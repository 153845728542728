import { $tm } from '@/plugins/i18n';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

class DateTime {
  /**
   * get week day as string from Date or datetime string
   * @param {Date|string} date
   * @return {string}
   */
  public getWeekDayFromDate(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    const days: string[] = $tm('DATA.WEEKDAYS');
    return days[date.getDay()] ?? '???';
  }

  /**
   * split a time string into hours, minutes and seconds
   * @param {string} time
   * @param {string} delimiter
   * @return {object}
   */
  public splitTime(time: string, delimiter: string = ':'): { hours: number; minutes: number; seconds: number } {
    const parts: string[] = time.split(delimiter);
    const data = { hours: 0, minutes: 0, seconds: 0 };

    // hours
    if (parts.length >= 1) {
      const hours: number = parseInt(parts[0]);

      if (hours && hours >= 0 && hours <= 23) {
        data.hours = hours;
      }
    }

    // minutes
    if (parts.length >= 2) {
      const minutes: number = parseInt(parts[1]);

      if (minutes && minutes >= 0 && minutes <= 59) {
        data.minutes = minutes;
      }
    }

    // seconds
    if (parts.length >= 3) {
      const seconds: number = parseInt(parts[2]);

      if (seconds && seconds >= 0 && seconds <= 59) {
        data.seconds = seconds;
      }
    }

    return data;
  }

  /**
   * merge a time object into a date instance
   * @param {Date} date
   * @param {object} time
   * @return {Date}
   */
  public mergeTimeIntoDate(date: Date, time: { hours?: number; minutes?: number; seconds?: number }): Date {
    date.setHours(
      time.hours || time.hours === 0 ? time.hours : date.getHours(),
      time.minutes || time.minutes === 0 ? time.minutes : date.getMinutes(),
      time.seconds || time.seconds === 0 ? time.seconds : date.getSeconds(),
    );

    return date;
  }

  /**
   * convert Date or datetime string to Date string in german format
   * @param {Date|string} date
   * @return {string}
   */
  public dateToDateString(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  /**
   * convert Date or datetime string to datetime string in german format
   * @param {Date|string} date
   * @return {string}
   */
  public dateToDateTimeString(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return `${this.dateToDateString(date)} - ${date.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }

  /**
   * convert Date or datetime string to date string in iso format
   * @param {Date|string} date
   * @return {string}
   */
  public dateToIsoDateString(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return `${date.getFullYear()}` + `-${(date.getMonth() + 1).toString().padStart(2, '0')}` + `-${date.getDate().toString().padStart(2, '0')}`;
  }

  /**
   * convert Date or datetime string to datetime string in iso format
   * @param {Date|string} date
   * @return {string}
   */
  public dateToIsoDateTimeString(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return (
      this.dateToIsoDateString(date) +
      ` ${date.getHours().toString().padStart(2, '0')}` +
      `:${date.getMinutes().toString().padStart(2, '0')}` +
      `:${date.getSeconds().toString().padStart(2, '0')}`
    );
  }

  /**
   * format date or date string to custom date format
   * @see https://momentjs.com/docs/#/displaying/format/
   * @param {string} date
   * @param {string} format
   * @return {string}
   */
  public format(date: Date | string, format: string = 'DD.MM.YYYY'): string {
    return moment(date).format(format);
  }

  /**
   * get time difference between two dates in hours
   * @param {Date|string} start
   * @param {Date|string} end
   * @param {number} precisi0n
   * @return {number}
   */
  public getHourDifference(start: Date | string, end: Date | string, precision: number = 1): number {
    const startDate = typeof start === 'string' ? new Date(start) : start;
    const endDate = typeof end === 'string' ? new Date(end) : end;

    const timeDifferenceInMillis = Math.abs(startDate.getTime() - endDate.getTime());
    const hoursDifference = timeDifferenceInMillis / 36e5;

    const multiplier = Math.pow(10, precision);

    return Math.round(hoursDifference * multiplier) / multiplier;
  }

  /**
   * get time difference between two dates in hours
   * @param {Date|string} start
   * @param {Date|string} end
   * @param {number} precisi0n
   * @return {number}
   */
  public getHourDifferenceDayjsAndCustomFormat(start: Date | string, end: Date | string): number {
    const parsedStart = dayjs(start, 'DD.MM.YYYY - HH:mm');
    const parsedEnd = dayjs(end, 'DD.MM.YYYY - HH:mm');
    return dayjs(parsedEnd).diff(dayjs(parsedStart), 'hours');
  }

  /**
   *
   * @param {Date} date
   * @returns {dayjs.Dayjs}
   */
  public getStartOfWeek(date: Date): dayjs.Dayjs {
    return dayjs(date).startOf('week').add(1, 'day');
  }

  /**
   *
   * @param {Date} date
   * @returns {dayjs.Dayjs}
   */
  public getEndOfWeek(date: Date): dayjs.Dayjs {
    return dayjs(date).endOf('week').add(1, 'day');
  }

  /**
   *
   * @param {Date} date
   * @returns {dayjs.Dayjs}
   */
  public getStartOfMonth(date: Date): dayjs.Dayjs {
    return dayjs(date).startOf('month');
  }

  /**
   *
   * @param {Date} date
   * @returns {dayjs.Dayjs}
   */
  public getEndOfMonth(date: Date): dayjs.Dayjs {
    return dayjs(date).endOf('month');
  }

  /**
   *
   * @param {Date} date
   * @returns {number}
   */
  public getMonthNumberOfDays(date: Date): number {
    return dayjs(date).daysInMonth();
  }

  /**
   *
   * @param {Date} date
   * @returns {string}
   */
  public formatDate(date: dayjs.Dayjs): string {
    return date.format('DD.MM.YYYY');
  }

  /**
   *
   * @param {Date} date
   * @returns {string}
   */
  public formatWeek(date: Date): string {
    const startOfWeek = this.getStartOfWeek(date);
    const endOfWeek = this.getEndOfWeek(date);
    return `${this.formatDate(startOfWeek)} - ${this.formatDate(endOfWeek)}`;
  }

  /**
   *
   * @param {Date} date
   * @returns {string}
   */
  public formatMonth(date: Date): string {
    const startOfMonth = this.getStartOfMonth(date);
    const endOfMonth = this.getEndOfMonth(date);
    return `${this.formatDate(startOfMonth)} - ${this.formatDate(endOfMonth)}`;
  }
}

export default new DateTime();
