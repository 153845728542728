export default {
  HEADING: 'Information zu Ihrer Bewerberauswahl',
  TEXT_INFO:
    'Sie haben sich zur Vertretung Ihres KV-Dienstes für den Bewerber {applicantName} entschieden. Mit Bestätigung dieser Information, nehmen Sie diesen verbindlich an und ein Wechsel der Vertretung ist danach nicht möglich.',
  TEXT_CANCEL: 'Klicken Sie auf Abbrechen, um zu der Bewerberauswahl zurückzukehren.',
  TEXT_CHECKBOX:
    'Ich bin einverstanden mit {applicantName} als Vertretung meines KV-Dienstes. Mir ist bewusst, dass ich die Bewerbung verbindlich annehme und ein Vertragsverhältnis mit dem gewählten Vertreter zustande kommt..',
  BUTTON_CANCEL: 'Abbrechen',
  BUTTON_SELECT: 'Bewerbung verbindlich annehmen und Dienstvertretungsvertrag akzeptieren',
};
