export default {
  META: {
    TITLE: 'Aktuelle KV-Dienste',
  },
  HEADING_BANNER: 'Aktuelle KV-Dienste',
  NO_RESULTS: 'Keine Ergebnisse für diese Suchparameter.',
  OPEN_FILTER: 'Filter',
  SORTING: 'Sortierung',
  SORT: {
    ASC: 'Datum aufsteigend',
    DESC: 'Datum absteigend',
  },
};
