import { defineStore, PiniaPluginContext, StoreDefinition } from 'pinia';
import { restoreDateProperties } from '@/plugins/pinia';
import { ServiceFeeRange } from '@/data/fees';

import Debug from '@/utils/Debug';
import OnboardingPartnerData from '@/definitions/interfaces/OnboardingPartnerData.i';
import OnboardingSubstituteData from '@/definitions/interfaces/OnboardingSubstituteData.i';

export interface QuickStartState {
  partner: OnboardingPartnerData | null;
  substitute: OnboardingSubstituteData | null;
}

const useQuickStartStore: StoreDefinition<'QuickStart', QuickStartState, {}, any> = defineStore('QuickStart', {
  state: (): QuickStartState => ({
    partner: {
      serviceType: null,
      state: null,
      dateTimeStart: null,
      dateTimeEnd: null,
      fee: ServiceFeeRange.suggestion,
      establishmentID: null,
      establishmentDoctorID: null,
      kvShareRate: 0,
    },
    substitute: {
      dateStart: null,
      dateEnd: null,
      states: null,
      serviceMobile: false,
      serviceOffice: false,
      triggerAction: {
        action: '',
        serviceId: '',
      },
    },
  }),
  actions: {
    /**
     * update the whole partner data object at once
     * @param {OnboardingPartnerData} partner
     * @return {void}
     */
    updatePartner(partner: OnboardingPartnerData): void {
      this.partner = partner;
    },

    /**
     * update the whole substitute data object at once
     * @param {OnboardingSubstituteData} substitute
     * @return {void}
     */
    updateSubstitute(substitute: OnboardingSubstituteData): void {
      this.substitute = substitute;
    },

    /**
     * reset whole partner data
     * @return {void}
     */
    resetPartner(): void {
      this.partner = {
        serviceType: null,
        state: null,
        dateTimeStart: null,
        dateTimeEnd: null,
        fee: ServiceFeeRange.min,
        establishmentID: null,
        establishmentDoctorID: null,
        kvShareRate: 0,
      };
    },

    /**
     * reset whole substitute data
     * @return {void}
     */
    resetSubstitute(): void {
      this.substitute = {
        dateStart: null,
        dateEnd: null,
        states: null,
        serviceMobile: false,
        serviceOffice: false,
        triggerAction: {
          action: '',
          serviceId: '',
        },
      };
    },
  },
  persist: {
    key: 'arztpool24.de-quickstart',
    debug: Debug.isEnabled(),
    afterRestore: (ctx: PiniaPluginContext): void => {
      restoreDateProperties(ctx.store.$state, {
        partner: [
          'dateTimeStart',
          'dateTimeEnd',
        ],
        substitute: [
          'dateStart',
          'dateEnd',
        ],
      });
    },
  },
});

export default useQuickStartStore;
