import { nextTick } from 'vue';
import { Auth } from 'aws-amplify';
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, Router, RouteRecordNormalized } from 'vue-router';

import CaptureException from '@/services/CaptureException';
import routes from '@/configs/routes';
import MetaData from '@/services/MetaData';

const router: Router = createRouter({
  history: createWebHistory('/'),
  scrollBehavior(): { left: number; top: number } {
    return { left: 0, top: 0 };
  },
  routes: routes,
});

// check authentication before routing
router.beforeResolve((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  // route requires authentication
  if (to.matched.some((record: RouteRecordNormalized) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()

      // user is authenticated
      .then((user): void => {
        if (user.attributes['custom:userType'] === 'operator') {
          return next();
        }

        if (to.meta.requiredUserType && user.attributes['custom:userType'] && to.meta.requiredUserType !== user.attributes['custom:userType']) {
          return next({ path: '/' });
        }
        return next();
      })

      // authentication missing
      .catch((): void => {
        next({
          path: '/',
          query: {
            redirect: window.location.pathname,
            login: '1',
          },
        });
      });
  } else {
    next();
  }
});

// update page meta-data after routing
router.afterEach((to: RouteLocationNormalized): void => {
  // wait for dom changes to be applied before changing meta-data
  nextTick().then((): void => {
    MetaData.setMetaData(to.meta);
  });
});

// AWS RUM page view tracking
router.afterEach(async (to: RouteLocationNormalized): Promise<void> => {
  // @ts-expect-error: Accessing private property eventCache
  if (window.AwsRum && typeof window.AwsRum.eventCache.addRecordToCache === 'function') {
    Auth.currentAuthenticatedUser()
      // user is authenticated
      .then((user): void => {
        // @ts-expect-error: Accessing private property eventCache
        window.AwsRum.eventCache.addRecordToCache('PageView', {
          pageId: to.fullPath,
          user_email: user.attributes['email'],
          user_type: user.attributes['custom:userType'],
          user_cognito_id: user.attributes['sub'],
        });
      })
      // authentication missing
      .catch((): void => {
        // @ts-expect-error: Accessing private property eventCache
        window.AwsRum.eventCache.addRecordToCache('PageView', {
          pageId: to.fullPath,
          user_email: 'n/a',
          user_type: 'n/a',
          user_cognito_id: 'n/a',
        });
      });
  }
});

export default router;

/**
 * default error handler for page routing
 * will suppress errors of getting redirected to the same page as its currently on
 * @param {object} err
 * @return {void}
 */
export const RouterSamePageErrorHandler = (err: Error): void => {
  const searchMessage: string = 'Avoided redundant navigation to current location';

  // ignore error regarding navigating to the same page as are already on
  if (err.name !== 'NavigationDuplicated' && !err.message.includes(searchMessage)) {
    // print any other errors to the console via debug utility
    CaptureException.send(err);
  }
};
