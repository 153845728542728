import { Amplify } from 'aws-amplify';

import AmplifyVue from '@aws-amplify/ui-vue';
import apiConfig from '@/configs/api';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: apiConfig.amplify.region,
    userPoolId: apiConfig.amplify.userPoolId,
    userPoolWebClientId: apiConfig.amplify.userPoolWebClientId,
  },
});

export default AmplifyVue;
