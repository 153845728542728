<template>
  <div>
    <v-dialog v-model="showModalResetPassword" width="560" :persistent="true">
      <v-card id="modalResetPassword">
        <v-card-title class="mb-5">
          <a class="modal-close" @click="showPasswordReset(false)">
            {{ $t('PASSWORD_RESET_MODAL.CLOSE') }}
            <v-icon icon="fal fa-times" />
          </a>
          <h1 class="mb-0">{{ $t('PASSWORD_RESET_MODAL.HEADING') }}</h1>
        </v-card-title>
        <v-card-text>
          {{ $t('PASSWORD_RESET_MODAL.INITIAL_TEXT') }}
        </v-card-text>
        <v-form @submit.prevent="resetPassword" ref="resetPasswordForm">
          <p id="error" v-if="error">{{ $t('PASSWORD_RESET_MODAL.ERROR.' + error) }}</p>
          <v-row no-gutters>
            <v-col class="pa-0 mb-10" cols="12">
              <v-text-field v-model="email" :label="$t('PASSWORD_RESET_MODAL.FORM.MAIL')" type="email" :rules="emailRules" :disabled="loading" />
            </v-col>
            <v-col class="pa-0 d-flex justify-start align-center" cols="12" md="6">
              <v-btn :class="{ 'mt-8': smAndDown }" :loading="loading" :ripple="false" class="btn-cta text-uppercase" elevation="0" type="submit">
                {{ $t('PASSWORD_RESET_MODAL.FORM.SUBMIT') }}
              </v-btn>
            </v-col>
            <v-col class="pa-0 d-flex justify-end align-center" cols="12" md="6">
              <a href="#" @click="showLogin">{{ $t('PASSWORD_RESET_MODAL.BACK_TO_LOGIN') }}</a>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { ref } from 'vue';
  import { defineComponent } from 'vue';
  import { useDisplay } from 'vuetify';

  import ResetPassword from '@/services/ResetPassword';
  import Validation from '@/services/Validation';
  import EventBus from '@/services/EventBus';
  import CaptureException from '@/services/CaptureException';

  export default defineComponent({
    name: 'ResetPasswordModal',
    components: {},
    setup() {
      const { smAndDown } = useDisplay();

      return {
        smAndDown,

        showModalResetPassword: ref(false),
        email: ref(''),
        password: ref(''),
        newPassword: ref(''),
        loading: ref(false),
        error: ref(''),

        emailRules: Validation.getEmailValidator(),
        resetPasswordForm: ref(null as unknown as HTMLFormElement),
      };
    },
    async beforeMount(): Promise<void> {
      // register event to open password reset modal
      EventBus.on(EventBus.keys.PASSWORD_RESET_OPEN, (): void => {
        this.showPasswordReset(true);
      });

      // register event to receive password reset error messages
      EventBus.on(EventBus.keys.PASSWORD_RESET_ERROR, (loginError): void => {
        this.error = (loginError as string).toUpperCase().split(' ').join('_');
      });
    },
    methods: {
      /**
       * toggle reset password modal state (open/close)
       * @return {void}
       */
      showPasswordReset(state: boolean): void {
        this.showModalResetPassword = state;
      },

      /**
       * reset password
       * @return {void}
       */
      async resetPassword(): Promise<void> {
        if (this.resetPasswordForm.isValid) {
          this.loading = true;

          try {
            await ResetPassword.resetPassword(this.email);
          } catch (e: any) {
            CaptureException.send(e);
          }

          this.loading = false;
          this.showLogin();
        }
      },

      /**
       * show login modal
       * @return {void}
       */
      showLogin(): void {
        this.showPasswordReset(false);
        EventBus.emit(EventBus.keys.LOGIN_OPEN, true);
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';
  @import '@/scss/configs/spacing';

  #modalResetPassword {
    .v-card-title {
      padding: 0 !important;
    }

    .v-card-text {
      padding: 0 !important;
      color: $color-marine;
      margin-bottom: $margin-3;
    }
  }
</style>
