export default {
  HEADING: 'Bei arztpool registrieren',
  CLOSE: 'Schließen',
  CONFIRMATION_NEXTSTEPS: [
    'Vielen Dank für Ihr Vertrauen und Ihre Registrierung. Sie haben von uns eine E-Mail bekommen, um Ihren Account zu bestätigen.',
    'Bitte klicken Sie den Link in dieser E-Mail. Bitte prüfen Sie auch, ob sich die E-Mail ggf. in Ihrem Spam Ordner befindet.',
  ],
  FORM: {
    EMAIL: 'E-Mail',
    PASSWORD: 'Passwort',
    TITLE: 'Titel',
    FIRSTNAME: 'Vorname',
    LASTNAME: 'Nachname',
    PASSWORD_CONFIRMATION: 'Passwort bestätigen',
    PASSWORD_SHOW: 'Passwort anzeigen',
    PASSWORD_HIDE: 'Passwort verbergen',
    SUBMIT: 'Registrieren',
  },
  ERROR: {
    PW_NOT_EQUAL_PWC: 'Passwort und Passwortbestätigung stimmen nicht überein.',
    USERTYPE_NOTSET: 'Bitte wählen Sie aus, ob Sie Dienste vertreten lassen wollen oder selber Dienste übernehmen wollen.',
    TOS_NOT_ACCEPTED: 'Bitte akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen.',
    PASSWORD_NOT_LONG_ENOUGH: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    PASSWORD_MUST_HAVE_LOWERCASE_CHARACTERS: 'Das Passwort muss mindestens einen Kleinbuchstaben beinhalten.',
    PASSWORD_MUST_HAVE_UPPERCASE_CHARACTERS: 'Das Passwort muss mindestens einen Großbuchstaben beinhalten.',
    PASSWORD_MUST_HAVE_NUMERIC_CHARACTERS: 'Das Passwort muss mindestens eine Zahl beinhalten.',
    PASSWORD_MUST_HAVE_SYMBOL_CHARACTERS: 'Das Passwort muss mindestens ein Sonderzeichen beinhalten.',
    AN_ACCOUNT_WITH_THE_GIVEN_EMAIL_ALREADY_EXISTS: 'Diese E-Mail ist bereits registriert.',
    INVALID_EMAIL_ADDRESS_FORMAT: 'Ungültiges E-Mail Format.',
  },
  FOOTER: {
    HEADING: 'Ich habe bereits einen Account.',
    LOGIN: 'Anmelden',
    AGREEMENT: [
      'Mit Erstellung eines Accounts stimmen Sie unseren',
      'AGB',
      'und',
      'Datenschutzbestimmungen',
      'zu.',
    ],
  },
};
