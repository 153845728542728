import { defineStore } from 'pinia';

import ServiceManagementData from '@/definitions/interfaces/ServiceManagementData.i';
import Debug from '@/utils/Debug';

export interface ServiceState {
  chosenService: { [p: string]: any } | null;
  tabIndexCache: number;
  serviceManagementData: ServiceManagementData | null;
}

const useServiceStore = defineStore('Service', {
  state: (): ServiceState => ({
    chosenService: null,
    tabIndexCache: 0,
    serviceManagementData: null,
  }),
  actions: {
    chooseService(service: {} | null): void {
      this.chosenService = service;
    },
    setServiceManagementData(data: ServiceManagementData | null): void {
      this.serviceManagementData = data;
    },
    cacheTabindex(index: number): void {
      this.tabIndexCache = index;
    },
  },
  persist: {
    key: 'arztpool24.de-general',
    debug: Debug.isEnabled(),
  },
});

export default useServiceStore;
