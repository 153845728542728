export default {
  HEADING: 'KV-Dienst am',
  HEADER: {
    LINK_BACK: 'Zurück',
    BOOKMARKS: 'Beobachter',
    APPLICATIONS: 'Bewerbungen',
  },
  SERVICE_OFFICE: 'Sitzdienst',
  SERVICE_MOBILE: 'Fahrdienst',
  INFORMATION: {
    HEADING: 'Angaben zum Dienst',
    TYPE: 'Dienstart',
    START: 'Dienstbeginn',
    END: 'Dienstende',
    DURATION: 'Dienstdauer',
    BREAK_START: 'Pausenbeginn',
    BREAK_END: 'Pausenende',
    BREAK_DURATION: 'Pausendauer',
    STATE: 'Bundesland',
    AREA: 'Dienstgebiet',
    INVOLVEMENT: 'Notfallschein-Beteiligung',
    INVOLVEMENT_DEFAULT: ' pro Schein (ab dem 1. Patienten)',
  },
  COMMENT: {
    HEADING: 'Kommentar zum Dienstgebiet',
  },
  DISCLAIMER: {
    HEADING: 'Hinweise',
    DESCRIPTION: 'Bitte beachten Sie vor Ihrer Bewerbung folgende Hinweise, die wir zu diesem Dienst bekommen haben.',
    BREAK_DESCRIPTION:
      '* Bitte beachten Sie: Dieser Dienst beinhaltet eine Pause zwischen zwei Einsätzen. Es handelt sich nicht um einen durchgehenden Dienst über die angegebene Dauer.',
  },
  CARD: {
    PUBLISHED: 'Veröffentlichter Dienst',
    OCCUPIED: 'Vergebener Dienst',
    CONCLUDED: 'Beendeter Dienst',
    CREATED_AT: 'Erstellt am',
    BOOKMARKS: 'Beobachter',
    APPLICATIONS: 'Bewerbungen',
    BUTTON_EDIT: 'Dienst bearbeiten',
    BUTTON_APPLICANTS: 'Zu den Bewerbern',
    BUTTON_OVERVIEW: 'Zu den Details',
    SUBSTITUTE: {
      FEE_PER_HOUR: 'Honorar pro Stunde',
      FEE_TOTAL: 'Honorar für gesamte Dienstzeit',
      FEE_PLACEMENT: 'Erst nach erfolgreicher Vermittlung dieses Dienstes fällt eine Vermittlungsgebühr von {price} an.',
      CONTRACT: 'Mit einer verbindlichen Bewerbung akzeptieren Sie im Falle einer erfolgreichen Vermittlung den Dienstvertretungsvertrag.',
      DEFAULT: {
        HEADING: 'Ihr Honorar pro Stunde',
        DESCRIPTION:
          'Geben Sie bitte an, zu welchem Stundenhonorar Sie diesen KV-Dienst vertreten möchten. Mit Ihrer Bewerbung verpflichten Sie sich, den Dienst zu übernehmen, sofern Ihre Bewerbung angenommen wird.',
        DESCRIPTION_MINMAX_EQUAL:
          'Mit Ihrer Bewerbung zum angegebenen Honorar verpflichten Sie sich, den Dienst zu übernehmen, sofern Ihre Bewerbung angenommen wird.',
        BUTTON_WATCHLIST_ADD: 'Auf die Merkliste',
        BUTTON_WATCHLIST_REMOVE: 'Von Merkliste entfernen',
        BUTTON_APPLY: 'Verbindlich bewerben',
      },
      APPLIED: {
        ALERT: 'Ihre Bewerbung wurde erfolgreich übermittelt.',
        DESCRIPTION:
          'Geben Sie bitte an, zu welchem Stundenhonorar Sie diesen KV-Dienst vertreten möchten. Mit Ihrer Bewerbung verpflichten Sie sich, den Dienst zu übernehmen, sofern Ihre Bewerbung angenommen wird.',
        HEADING: 'Ihr Angebot',
      },
      ASSIGNED: {
        ALERT: 'Herzlichen Glückwunsch, Sie sind der Vertreter für diesen KV-Dienst.',
        HEADING: 'Ihr Honorar',
        DESCRIPTION: 'Sie können nun Nachrichten mit Ihrem Auftraggeber austauschen und den Dienstvertretungsvertrag herunterladen.',
        BUTTON: 'Details zum Dienst anzeigen',
      },
      CONCLUDED: {
        ALERT: 'Der Dienst wurde beendet.',
        DESCRIPTION: 'Finden Sie andere passende KV-Dienste und bewerben Sie sich in wenigen Schritten.',
        BUTTON_MARKETPLACE: 'Weitere Dienste finden',
        BUTTON_DETAILS: 'Details zum Dienst anzeigen',
      },
    },
  },
  PRIORITY: {
    HEADING:
      'Sie möchten den Dienst dringend abgeben und haben noch nicht den passenden Vertreter gefunden? Wählen Sie die Option "Priority", um mehr Aufmerksamkeit zu erzielen.',
    LABEL: 'Priority-Dienst',
    DESCRIPTION:
      'Diese Option empfiehlt sich, wenn es sich um einen kurzfristigen Termin handelt oder es um ein schnelles Ergebnis bei der Vertreterfindung gehen soll. Ihr Dienst wird hervorgehoben und erhält so schneller passende Angebote von Bewerbern.',
    SUBMIT: 'Option "Priority" verbindlich buchen',
  },
  IMMEDIATE: {
    HEADING: 'Sie möchten den Dienst unbedingt haben? Wählen Sie die Sofort-Zusage, um sich den Dienst direkt und ohne Wartezeit zu sichern.',
    FEE_PER_HOUR: 'Sofort-Zusage Honorar pro Stunde',
    FEE_TOTAL: 'Sofort-Zusage Honorar für gesamte Dienstzeit',
    SUBMIT: 'Sofort-Zusage verbindlich wählen',
  },
  SIMILAR: {
    HEADING: 'Ähnliche KV-Dienste',
  },
};
