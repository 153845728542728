import Format from '@/utils/Format';

export interface FeeRange {
  min: number;
  mid: number;
  max: number;
  suggestion: number;
}

export interface EmergencyFee {
  value: number;
  label: string;
}

export interface FeeTier {
  tier: number;
  value: number;
}

export interface DiscountStep {
  numberOfServices: number;
  discount: number;
}

export interface SubstituteServiceFee {
  minHours: number;
  maxHours: number;
  value: number;
}

export const ServiceFeeRange: FeeRange = {
  min: 45,
  mid: 120,
  max: 200,
  suggestion: 75,
};

export const ServiceFeeRangeMobile: FeeRange = {
  min: 45,
  mid: 120,
  max: 200,
  suggestion: 75,
};

export const ServiceFeeRangeOffice: FeeRange = {
  min: 65,
  mid: 130,
  max: 200,
  suggestion: 75,
};

export const EmergencyOfficeFees: EmergencyFee[] = [
  {
    value: 5,
    label: Format.price(5),
  },
  {
    value: 10,
    label: Format.price(10),
  },
  {
    value: 15,
    label: Format.price(15),
  },
  {
    value: 0,
    label: Format.price(0),
  },
];

export const EmergencyMobileFees: EmergencyFee[] = [
  {
    value: 20,
    label: Format.price(20),
  },
  {
    value: 30,
    label: Format.price(30),
  },
  {
    value: 40,
    label: Format.price(40),
  },
  {
    value: 50,
    label: Format.price(50),
  },
  {
    value: 0,
    label: Format.price(0),
  },
];

export const AgencyFees: FeeTier[] = [
  {
    tier: 3,
    value: 179,
  },
  {
    tier: 2,
    value: 149,
  },
  {
    tier: 1,
    value: 99,
  },
];

export const Discounts: DiscountStep[] = [
  {
    numberOfServices: 4,
    discount: 5,
  },
  {
    numberOfServices: 21,
    discount: 7.5,
  },
];

export const SubstituteServiceFees: SubstituteServiceFee[] = [
  {
    minHours: 0,
    maxHours: 3.5,
    value: 4.99,
  },
  {
    minHours: 4,
    maxHours: 9.5,
    value: 9.99,
  },
  {
    minHours: 10,
    maxHours: 24,
    value: 13.99,
  },
];

export const PriorityFee: number = 15;
export const CareFreeFee: number = 199;
export const InstantAssignFee: number = 0;
