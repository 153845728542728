export default {
  HEADING: 'Information zu Ihrer Vermittlungsgebühr',
  SUB_HEADING: [
    'Sie haben das Rundum-Sorglos-Paket dazugebucht..',
    'Rundum-Sorglos-Paket',
    'Persönlicher Berater',
    'Priority',
  ],
  TEXT: [
    'Sie möchten Zeit sparen und die Auswahl des Vertreters in unsere Hände geben? Wir übernehmen mit dem Rundum-Sorglos-Paket die gesamte Auftragskommunikation. In der Vermittlungsgebühr für das "Rundum-Sorglos-Paket" von 199€ ist zusätzlich auch die Priority-Option enthalten. Ihr KV-Dienst wird unseren Vertretungsärzten empfohlen und in der Liste hervorgehoben. Dadurch erlangt Ihr Dienst höhere Aufmerksamkeit und erhält schneller passende Angebote.',
    'Ich bin einverstanden mit der Vermittlungsgebühr. Mir ist bewusst, dass diese erst nach Zuteilung des Dienstes fällig ist.',
  ],
  BUTTON: {
    CANCEL: 'Abbrechen',
    BOOK: 'Zubuchen',
  },
};
