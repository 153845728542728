import { defineStore, PiniaPluginContext, StoreDefinition } from 'pinia';
import { restoreDateProperties } from '@/plugins/pinia';

import Debug from '@/utils/Debug';

export interface WizardState {
  userType: string | null;
  isQuickRegistration: boolean;
  partner: {
    title: string | null;
    firstname: string | null;
    lastname: string | null;
    institutionType: string | null;
    institutionName: string | null;
    doctorTitle: string | null;
    doctorName: string | null;
    doctorLANR: string | null;
    emergencyTicketRefund: number | null;
    serviceArea: string | null;
    serviceAreaId: string | number | null;
    isCustomServiceArea: boolean;
    isCustomDepartment: boolean;
  };
  substitute: {
    title: string | null;
    gender: string | null;
    firstname: string | null;
    lastname: string | null;
    birth: Date | null;
    phone: string | null;
    mobile: string | null;
    mobile_additional: string | null;
    street: string | null;
    house: string | null;
    postcode: string | null;
    place: string | null;
    state: string | null;
    accountOwner: string | null;
    iban: string | null;
    bic: string | null;
    bank: string | null;
    taxNumber: string | null;
    specialField: string | null;
    confirmation: string | null;
    licenseToPractice: string | null;
    licenseToPracticeFilename: string | null;
    specialistCertificate: string | null;
    specialistCertificateFilename: string | null;
    liabilityInsurance: string | null;
    liabilityInsuranceFilename: string | null;
    liabilityInsuranceName: string | null;
    liabilityInsuranceNumber: string | null;
  };
}

const useWizardStore: StoreDefinition<'Wizard', WizardState, {}, any> = defineStore('Wizard', {
  state: (): WizardState => ({
    userType: null,
    isQuickRegistration: false,
    partner: {
      title: null,
      firstname: null,
      lastname: null,
      institutionType: null,
      institutionName: null,
      doctorTitle: null,
      doctorName: null,
      doctorLANR: null,
      emergencyTicketRefund: null,
      serviceArea: null,
      serviceAreaId: null,
      isCustomServiceArea: false,
      isCustomDepartment: false,
    },
    substitute: {
      title: null,
      gender: null,
      firstname: null,
      lastname: null,
      birth: null,
      phone: null,
      mobile: null,
      mobile_additional: null,
      street: null,
      house: null,
      postcode: null,
      place: null,
      state: null,
      accountOwner: null,
      iban: null,
      bic: null,
      bank: null,
      taxNumber: null,
      specialField: null,
      confirmation: null,
      licenseToPractice: null,
      licenseToPracticeFilename: null,
      specialistCertificate: null,
      specialistCertificateFilename: null,
      liabilityInsurance: null,
      liabilityInsuranceFilename: null,
      liabilityInsuranceName: null,
      liabilityInsuranceNumber: null,
    },
  }),
  persist: {
    key: 'arztpool24.de-wizard',
    debug: Debug.isEnabled(),
    afterRestore: (ctx: PiniaPluginContext): void => {
      restoreDateProperties(ctx.store.$state, {
        substitute: [
          'birth',
          'insuranceValidity',
        ],
      });
    },
  },
});

export default useWizardStore;
