export default {
  HEADLINE: 'Bestätigung Ihres Accounts',
  DESCRIPTION: 'Vielen Dank für Ihre Registrierung.',
  RESEND_CONFIRMATION: 'Bestätigungslink erneut versenden',
  LOGIN: 'Anmelden',
  CONFIRMATION: [
    'Wir haben Ihren Nutzeraccount bestätigt.',
    'Bitte melden Sie sich nun an und vervollständigen Sie Ihr Profil.',
  ],
  ERROR: {
    INVALID_ACCOUNT_INFORMATION: 'Dies sind keine gültigen Anmeldeinformationen.',
    EXPIREDCODEEXCEPTION:
      'Dieser Bestätigungslink ist abgelaufen - bitte nutzen Sie nur den letzten Ihnen zugesandten Link. Sie können einen neuen Bestätigungslink anfordern.',
    NOTAUTHORIZEDEXCEPTION: 'Ihr Nutzerkonto wurde bereits bestätigt. Sie können Sich jederzeit einloggen.',
    CODEMISMATCHEXCEPTION:
      'Dieser Bestätigungslink ist abgelaufen - bitte nutzen Sie nur den letzten Ihnen zugesandten Link. Sie können einen neuen Bestätigungslink anfordern.',
    USER_IS_NOT_CONFIRMED: 'Dieses Nutzerkonto wurde noch nicht bestätigt.',
    PASSWORD_RESET_REQUIRED_FOR_THE_USER: 'Sie müssen Ihr Passwort ändern, um sich einzuloggen.',
  },
};
