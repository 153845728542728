import { Auth } from 'aws-amplify';
import EventBus from './EventBus';

class ResetPassword {
  /**
   * reset user password via amplify/cognito
   * @param {string} email
   * @return {Promise}
   */
  public async resetPassword(email: string): Promise<void> {
    try {
      await Auth.forgotPassword(email.toLowerCase());
    } catch (error: any) {
      if (error) {
        EventBus.emit(EventBus.keys.PASSWORD_RESET_ERROR, error);
      }

      throw error;
    }
  }

  /**
   * request new passwort via amplify/cognito
   * @param {string} email
   * @param {string} password
   * @param {string} code
   * @return {Promise}
   */
  public async setNewPassword(email: string, password: string, code: string): Promise<void> {
    try {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), code, password);
    } catch (error: any) {
      if (error) {
        EventBus.emit(EventBus.keys.PASSWORD_RESET_ERROR, error.code);
      }

      throw error;
    }
  }
}

export default new ResetPassword();
