export default {
  MENU: {
    TITLE: 'Menü',
    LINKS: {
      DASHBOARD: 'Dashboard',
      SERVICES: 'Dienste',
      DOCUMENTS: 'Dokumente',
    },
  },
  MY_DOCTOR_POOL: {
    TITLE: 'Mein arztpool',
    TEASER_LOGIN: 'Klicken Sie hier, um sich anzumelden / zu registrieren.',
    TEASER_LOGOUT: 'Klicken Sie hier, um sich abzumelden.',
    LOGIN: 'Anmelden / Registrieren',
    LOGOUT: 'Abmelden',
    LINKS: {
      DASHBOARD: '@:NAVIGATION.MENU.LINKS.DASHBOARD',
      SERVICES: '@:NAVIGATION.MENU.LINKS.SERVICES',
      DOCUMENTS: '@:NAVIGATION.MENU.LINKS.DOCUMENTS',
      MESSAGES: 'Nachrichten',
      OPERATORS: 'Operators',
      PROVIDE_SERVICE: 'KV-Dienst vertreten lassen',
      REPRESENT_SERVICE: 'KV-Dienst übernehmen',
      MVZ: 'Dienstüberlassung für MVZ',
      HELP: 'Technische Hilfe',
      SUBSTITUTES: {
        YOUR_ACCOUNT: 'Ihr Account',
        BASIC_DATA: 'Stammdaten',
        BILLING_DATA: 'Abrechnungsdaten',
        INSURANCE: 'Versicherungen',
        HEALTH_INSURANCE: 'Qualifikation/Ausstattung',
      },
      PARTNER: {
        INSTITUTION: 'Einrichtung',
        ESTABLISHMENT: 'Betriebsstätten',
      },
    },
  },
};
