export default {
  HEADING: 'KV-Dienst eintragen',
  CLOSE: 'Schließen',
  SUB_HEADING: 'Zusatzoptionen',
  OPTIONS: [
    {
      LABEL: 'Rundum-Sorglos-Paket',
      INFO: 'Sie möchten Zeit sparen und die Auswahl des Vertreters in unsere Hände geben? Wir übernehmen mit dem Rundum-Sorglos-Paket die gesamte Auftragskommunikation. In der Vermittlungsgebühr für das "Rundum-Sorglos-Paket" von 199€ ist zusätzlich auch die Priority-Option enthalten. Ihr KV-Dienst wird unseren Vertretungsärzten empfohlen und in der Liste hervorgehoben. Dadurch erlangt Ihr Dienst höhere Aufmerksamkeit und erhält schneller passende Angebote.',
    },
    {
      LABEL: 'Sofort-Zusage',
      INFO: 'Die Sofort-Zusage ermöglicht dem Vertretungsarzt, sich Ihren Dienst direkt und ohne Wartezeit zu sichern. Er akzeptiert damit automatisch das Mindesthonorar von {minFee}€/Std. für diesen Dienst. Sobald sich ein Vertreter für Ihren Dienst entscheidet, entstehen verbindliche Kosten für die arztpool-Vermittlungsgebühr.',
    },
    {
      LABEL: 'Priority',
      INFO: 'Einfach Aufmerksamkeit erzielen - Ihr KV-Dienst wird unseren Vertretungsärzten empfohlen und in der Liste hervorgehoben. Dadurch erlangt Ihr Dienst höhere Aufmerksamkeit und erhält schneller passende Angebote.',
    },
  ],
};
