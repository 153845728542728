export default {
  META: {
    TITLE: 'On-Boarding Wizard',
  },
  STEP: 'Schritt',
  BUTTON_BACK: 'Zurück',
  BUTTON_NEXT: 'Bestätigen und weiter',
  PARTNER: {
    HEADER: {
      OPENER: 'Mehr Informationen',
      TIME_INFO: 'Ihr KV-Dienst am {start} - {end}',
      TIME_FORMAT: '{date}, {time} Uhr',
      SERVICE_MOBILE: 'Fahrdienst',
      SERVICE_OFFICE: 'Sitzdienst',
      START_TIME: 'Startzeit',
      END_TIME: 'Endzeit',
      START_DATE: 'Dienstbeginn',
      END_DATE: 'Dienstende',
    },
    INSTITUTION: {
      HEADING: 'Um welche Art von Institution handelt es sich?',
      DESCRIPTION: 'In wenigen Schritten ist ihr KV-Dienst online. Fügen Sie die fehlenden Angaben hinzu, um Ihren KV-Dienst vertreten zu lassen.',
      LABEL: {
        MVZ: 'MVZ',
        MVZ_NAME: 'Name des MVZ',
        MVZ_INSTRUCTION: 'Als MVZ haben Sie später im Bereich "Meine Daten" die Möglichkeit, einzelne Betriebsstätten anzulegen.',
        PRACTICE: 'Einzelpraxis/Gemeinschaftspraxis',
        PRACTICE_NAME: 'Name bzw. Praxisname',
        PRACTICE_NAME_DISCLAIMER:
          'Falls Sie nicht wissen, welchen Praxisnamen Sie hier angeben sollen, nutzen Sie einfach Ihren Namen als „Praxis Vorname Nachname“. Dies können Sie später jederzeit editieren',
        DOCTOR: 'Zu vertretender Arzt',
        DOCTOR_TITLE: 'Titel',
        DOCTOR_NAME: 'Name (Vor- und Nachname)',
        DOCTOR_LANR: 'LANR',
      },
    },
    SERVICE_AREA: {
      HEADING: 'In welchem Dienstgebiet sind Sie tätig?',
      DESCRIPTION: 'In wenigen Schritten ist Ihr KV-Dienst online. Fügen Sie die fehlenden Angaben hinzu, um Ihren KV-Dienst vertreten zu lassen.',
      LABEL: {
        STATE: 'Bundesland',
        AREA: 'Dienstgebiet',
        AREA_ADD: 'Dienstgebiet ergänzen',
        DEPARTMENT: 'Dienststelle',
        DEPARTMENT_ADD: 'Dienststelle ergänzen',
        DEPARTMENT_INSTRUCTION:
          'Bitte teilen Sie uns hier wichtige Informationen über das Dienstgebiet mit, z.B. die Adresse und Erreichbarkeit der Sitzdienstpraxis oder Anlaufstelle für den Fahrdienst.',
        CHOOSE: 'Aus Liste wählen',
      },
      CUSTOM_INFO:
        'Die hier angegebenen Informationen, werden von unserem Kundendienst geprüft, bevor der angelegte Dienst freigegeben wird oder Sie Ihrem Profil hinzugefügt werden können.',
    },
    EMERGENCY_TICKET: {
      HEADING: 'Beteiligung an den Notfallscheinen',
      DESCRIPTION:
        'Für potentielle Vertreter wird der Dienst attraktiver, wenn Sie an den Notfallscheinen beteiligt werden. Sie als Auftraggeber entscheiden, in welcher Höhe Sie den Vertretungsarzt an jedem Notfallschein beteiligen.\n\nNach unseren Erfahrungen können Sie über das EBM-Honorar mit einer Gegenfinanzierung von bis zu 50€ pro Patient/Notfallschein für Ihren Bereitschaftsdienst rechnen.',
      LABEL: {
        PER_EMERGENCY: 'Pro Notfallschein',
        NO_SHARE: 'Nein, ich möchte meinen Vertreter nicht an den Notfallscheinen beteiligen.',
      },
    },
  },
  SUBSTITUTE: {
    CONTACT: {
      HEADING: 'Vervollständigen Sie in wenigen Schritten Ihr Profil',
      DESCRIPTION: 'In wenigen Schritten können Sie sich online bewerben. Fügen Sie die fehlenden Angaben hinzu, um KV-Dienste vertreten zu können.',
      LABEL: {
        TITLE: 'Titel',
        GENDER: 'Geschlecht',
        FIRSTNAME: 'Vorname',
        LASTNAME: 'Nachname',
        BIRTHDATE: 'Geburtsdatum*',
        PHONE: 'Festnetznummer',
        MOBILE: 'Handynummer',
        MOBILE_ADDITIONAL: 'Weitere Handynummer',
      },
    },
    ADDRESS: {
      HEADING: 'Wie lautet Ihre Rechnungsadresse?',
      DESCRIPTION: 'In wenigen Schritten können Sie sich online bewerben. Fügen Sie die fehlenden Angaben hinzu, um KV-Dienste vertreten zu können.',
      LABEL: {
        STREET: 'Straße',
        HOUSE: 'Hausnummer',
        POSTCODE: 'PLZ',
        CITY: 'Ort',
        STATE: 'Bundesland',
      },
      POSTCODE_ERROR: 'Keine gültige Postleitzahl',
    },
    PAYMENT: {
      HEADING: 'Informationen für Ihre Honorarabrechnung',
      DESCRIPTION: 'In wenigen Schritten können Sie sich online bewerben. Fügen Sie die fehlenden Angaben hinzu, um KV-Dienste vertreten zu können.',
      LABEL: {
        ACCOUNT_OWNER: 'Kontoinhaber',
        IBAN: 'IBAN',
        BIC: 'BIC',
        BANK: 'Bank',
        TAX_NUMBER: 'Steuernummer',
      },
    },
    QUALIFICATION: {
      HEADING: 'Ihre Qualifikation',
      DESCRIPTION:
        'Um sich als Honorararzt bei arztpool bewerben zu können, benötigen Sie Ihre Approbationsurkunde und Ihre Facharzturkunde. Sie können diesen Schritt auch später in Ihrem Dashboard durchführen.',
      OPTIONAL:
        'Falls Sie von Ihrer Approbation oder Ihrer Facharzturkunde gerade kein gescanntes PDF zur Hand haben, lassen Sie diese Felder erstmal leer. Sie können uns diese später noch ihn Ihrem Profil nachreichen.',
      LABEL: {
        SPECIALIZATION: 'Fachrichtung',
        CONFIRMATION:
          'Hiermit bestätige ich, dass ich mich mindestens im dritten Jahr meiner ärztlichen Weiterbildung befinde oder diese bereits abgeschlossen habe.',
        LICENSE: 'Ihre Approbationsurkunde',
        CERTIFICATE: 'Ihre Facharzturkunde',
        SELECT_FILE: 'Datei auswählen',
        SKIP: 'Später durchführen',
      },
    },
    INSURANCE: {
      HEADING: 'Versicherungsnachweis',
      DESCRIPTION:
        'Um sich als Honorararzt bei arztpool bewerben zu können, benötigen Sie eine aktuelle Bestätigung Ihrer Berufshaftpflichtversicherung. Sie können diesen Schritt auch später in Ihrem Dashboard durchführen.',
      OPTIONAL:
        'Falls Sie Ihre Versicherungsinformationen gerade nicht zur Hand haben, lassen Sie diese Felder erstmal leer. Sie können uns diese später noch ihn Ihrem Profil nachreichen.',
      LABEL: {
        LIABILITY: 'Ihre Berufshaftpflicht (Versicherungspolice)',
        NAME: 'Name der Versicherung',
        NUMBER: 'Nummer der Police',
        VALIDITYFROM: 'Ab wann ist die Versicherung gültig?',
        VALIDITYTO: 'Bis wann ist die Versicherung gültig?',
        SELECT_FILE: 'Datei auswählen',
        SKIP: 'Später durchführen',
      },
    },
  },
  REGISTER: {
    HEADING: 'Registrieren Sie Ihren Account',
    DESCRIPTION: 'Bitte registrieren Sie Ihren Account und klicken Sie dann in der Bestätigungs E-Mail auf den Link, um ihre E-Mail Adresse zu bestätigen.',
    CONFIRMATION_NEXTSTEPS: [
      'Vielen Dank für Ihr Vertrauen und Ihre Registrierung. Sie haben von uns eine E-Mail bekommen, um Ihren Account zu bestätigen.',
      'Bitte klicken Sie den Link in dieser E-Mail. Bitte prüfen Sie auch, ob sich die E-Mail ggf. in Ihrem Spam Ordner befindet.',
    ],
    LABEL: {
      EMAIL: 'E-Mail',
      PASSWORD: 'Passwort',
      PASSWORD_CONFIRMATION: 'Passwort bestätigen',
      PASSWORD_SHOW: 'Passwort anzeigen',
      PASSWORD_HIDE: 'Passwort verbergen',
      SUBMIT: 'Registrieren',
    },
    ERROR: {
      PW_NOT_EQUAL_PWC: 'Passwort und Passwortbestätigung stimmen nicht überein.',
      USERTYPE_NOTSET: 'Bitte wählen Sie aus, ob Sie Dienste vertreten lassen wollen oder selber Dienste übernehmen wollen.',
      TOS_NOT_ACCEPTED: 'Bitte akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen.',
      PASSWORD_NOT_LONG_ENOUGH: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
      PASSWORD_MUST_HAVE_LOWERCASE_CHARACTERS: 'Das Passwort muss mindestens einen Kleinbuchstaben beinhalten.',
      PASSWORD_MUST_HAVE_UPPERCASE_CHARACTERS: 'Das Passwort muss mindestens einen Großbuchstaben beinhalten.',
      PASSWORD_MUST_HAVE_NUMERIC_CHARACTERS: 'Das Passwort muss mindestens eine Zahl beinhalten.',
      PASSWORD_MUST_HAVE_SYMBOL_CHARACTERS: 'Das Passwort muss mindestens ein Sonderzeichen beinhalten.',
      AN_ACCOUNT_WITH_THE_GIVEN_EMAIL_ALREADY_EXISTS: 'Diese E-Mail ist bereits registriert.',
      INVALID_EMAIL_ADDRESS_FORMAT: 'Ungültiges E-Mail Format.',
    },
    AGREEMENT: [
      'Mit Erstellung eines Accounts stimmen Sie unseren',
      'AGB',
      'und',
      'Datenschutzbestimmungen',
      'zu.',
    ],
  },
};
