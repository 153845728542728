import apolloClient from '@/plugins/apollo';
import { PROFILE_QUERY, ROLES_QUERY } from '@/data/gql/queries';
import { REGISTER_PROFILE, UPDATE_PROFILE } from '@/data/gql/mutations';
import Stores from '@/stores';
import Debug from '@/utils/Debug';
import CaptureException from '@/services/CaptureException';
import DateTime from '@/utils/DateTime';
import { Auth } from 'aws-amplify';
import { ID_TOKEN } from '@/data/cognito';
import UserProfile from '@/definitions/interfaces/Profile.i';
import EventBus from '@/services/EventBus';

class Profile {
  /**
   * fetch a user profile
   * @return {Promise}
   */
  public async fetchProfile(): Promise<void> {
    Stores.profileData.loadingProfile = true;
    const _profile = await apolloClient.defaultClient.query({
      query: PROFILE_QUERY,
      fetchPolicy: 'no-cache',
    });
    if (_profile.data.profile !== null) {
      Stores.profileData.profile = _profile.data.profile;
      Stores.profileData.roles = _profile.data.profile.roles;
      await this.filterPlaceholders();
    }
    Stores.profileData.loadingProfile = false;
  }

  /**
   * while setting up the account we might have set some placeholders;
   * this method cleans those from the profile, so the user doesn't see them
   *
   */
  async filterPlaceholders(): Promise<void> {
    for (const key of Object.keys(Stores.profileData.profile)) {
      if (Stores.profileData.profile[key] === '####PLACEHOLDER####' || Stores.profileData.profile[key] === '0') {
        Stores.profileData.profile[key] = null;
      }
    }
  }

  /**
   * fetch the roles configured for the user
   * @return {Promise}
   */
  public async fetchRoles(): Promise<void> {
    Stores.profileData.loadingProfile = true;
    const _roles = await apolloClient.defaultClient.query({
      query: ROLES_QUERY,
      fetchPolicy: 'no-cache',
    });
    Stores.profileData.roles = _roles.data.profile.roles;
    Stores.profileData.loadingProfile = false;
  }

  /**
   * register a new profile
   * @param {UserProfile} newProfile
   * @return {Promise.<boolean>}
   */
  public async registerProfile(newProfile: UserProfile): Promise<boolean> {
    EventBus.emit(EventBus.keys.UPDATE_SETUP_STEP, 'SETUP_PROFILE');
    const _response = await apolloClient.defaultClient.mutate({
      mutation: REGISTER_PROFILE,
      variables: {
        title: newProfile.title,
        gender: newProfile.gender,
        firstName: newProfile.firstName,
        lastName: newProfile.lastName,
        birthDate: DateTime.dateToIsoDateString(newProfile.birthDate as Date),
        mobile1: newProfile.mobile1,
        mobile2: newProfile.mobile2,
        fax: newProfile.fax,
        phone: newProfile.phone,
      },
      fetchPolicy: 'network-only',
    });
    if (_response.data.register === 'succeeded') {
      await this.fetchProfile();
      return true;
    } else {
      return false;
    }
  }

  /**
   * update the profile in the store and in the backend
   * @return {Promise.<boolean>}
   */
  public async updateProfile(newProfile: UserProfile): Promise<boolean> {
    Stores.profileData.profile = newProfile;
    const _response = await apolloClient.defaultClient.mutate({
      mutation: UPDATE_PROFILE,
      variables: {
        title: Stores.profileData.profile.title,
        gender: Stores.profileData.profile.gender,
        firstName: Stores.profileData.profile.firstName,
        lastName: Stores.profileData.profile.lastName,
        birthDate: DateTime.dateToIsoDateString(Stores.profileData.profile.birthDate),
        mobile1: Stores.profileData.profile.mobile1,
        mobile2: Stores.profileData.profile.mobile2,
        fax: Stores.profileData.profile.fax,
        phone: Stores.profileData.profile.phone,
      },
    });
    if (_response.data.updateProfile === 'succeeded') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * get user attributes and information out of the current instance of amplify Auth
   *
   * @return {Promise}
   */
  public async fetchUser(): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const info = await Auth.currentUserInfo();

      Stores.userData.userType = info['attributes']['custom:userType'];

      const expires = user.getSignInUserSession().getIdToken().payload.exp - Math.floor(new Date().getTime() / 1000);
      const token = user.getSignInUserSession().getIdToken().jwtToken;

      localStorage.setItem(ID_TOKEN, token);
      Stores.userData.idToken = token;

      Debug.toConsole(`Token expires in ${expires} seconds`);

      setTimeout(async () => {
        Debug.toConsole('Renewing Token');
        await this.fetchUser();
      }, expires * 1000);

      Stores.userData.attributes = {
        type: user['attributes']['custom:userType'],
        email: user['attributes']['email'],
        email_verified: user['attributes']['email_verified'],
        sub: user['attributes']['sub'],
      };
      Stores.userData.signedIn = true;
    } catch (err) {
      CaptureException.send(err);
    }
  }
}

export default new Profile();
