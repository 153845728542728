export default {
  META: {
    TITLE: 'Dokumente',
  },
  HEADING: 'Ihre Dokumente',
  NO_DATA: 'Noch keine Daten vorhanden',
  TABLE: {
    ROWS_PER_PAGE: 'Zeilen pro Seite',
    NO_DATA: 'Keine Daten geladen',
    SEPARATOR: 'von',
  },
  CONFIRM: {
    YES: 'Ja',
    NO: 'Nein',
  },
  CONTRACTS: {
    TITLE: 'Verträge',
    HEADING: 'Ihre Dienstvertretungsverträge',
    DESCRIPTION: 'Zu jedem Ihrer Dienste finden Sie hier den entsprechenden Dienstvertretungsvertrag, den Sie sich für Ihre Unterlagen herunterladen können.',
    LOADING_ERROR: 'Ihre Verträge konnten nicht geladen werden.',
    TABLE_HEADERS: {
      TYPE: 'Diensttyp',
      START: 'Dienststart',
      END: 'Dienstende',
      SERVICE_AREA: 'Dienstgebiet',
      CONFIRMED: 'Bestätigt',
      ACTIONS: 'Ansehen',
    },
  },
  SUBSTITUTE: {
    INVOICES: {
      TITLE: 'Rechnungsvorlage',
      HEADING: 'Ihre Rechnungsvorlagen',
      DESCRIPTION:
        'Zu jedem Dienst, den Sie über arztpool vermittelt bekommen und der vom Kunden abgeschlossen wurde, finden Sie hier eine Rechnungsvorlage mit allen relevanten Daten für den Dienst.',
      LOADING_ERROR: 'Ihre Rechnungsvorlagen konnten nicht geladen werden.',
      TABLE_HEADERS: {
        TYPE: 'Diensttyp',
        START: 'Dienststart',
        END: 'Dienstende',
        SERVICE_AREA: 'Dienstgebiet',
        CONFIRMED: 'Bestätigt',
        ACTIONS: 'Ansehen',
      },
    },
  },
  SERVICE_OFFICE: 'Sitzdienst',
  SERVICE_MOBILE: 'Fahrdienst',
  NO_RESULTS: 'Keine Einträge',
  FILTER: {
    ALL: 'Alle',
  },
  SORTING: 'Sortierung',
  SORT: {
    ASC: 'Aufsteigend',
    DESC: 'Absteigend',
  },
};
