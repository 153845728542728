import { defineStore } from 'pinia';
import { Auth } from 'aws-amplify';
import { ID_TOKEN } from '@/data/cognito';

import useServiceStore from '@/stores/Service';
import useProfileDataStore from '@/stores/ProfileData';
import UserAttributes from '@/definitions/interfaces/UserAttributes.i';
import Debug from '@/utils/Debug';

export interface UserDataState {
  user: string | null;
  attributes: UserAttributes | null;
  servicesWatchlist: any[];
  userType: string | null;
  signedIn: boolean;
  completedRegistration: boolean;
  idToken: string | null;
  loginError: string | null;
  signupError: string | null;
  forgotPasswordError: string | null;
  forgotPassword: string | null;
  changePasswordError: string | null;
  changePassword: string | null;
  signedUpUser: string | null;
  passwordChallenge: boolean;
}

const useUserDataStore = defineStore('UserData', {
  state: (): UserDataState => ({
    user: null,
    attributes: null,
    servicesWatchlist: [],
    userType: null,
    signedIn: false,
    completedRegistration: false,
    idToken: '',
    loginError: '',
    signupError: '',
    forgotPasswordError: '',
    forgotPassword: '',
    changePasswordError: '',
    changePassword: '',
    signedUpUser: null,
    passwordChallenge: false,
  }),
  actions: {
    signIn(): void {
      this.signedIn = true;
    },
    signOut() {
      this.signedIn = false;
    },
    completeRegistration(): void {
      this.completedRegistration = true;
    },
    newIdToken(token: string): void {
      this.idToken = token;
    },
    removeIdToken(): void {
      this.idToken = '';
    },
    setUserType(type: string | null): void {
      this.userType = type;
    },
    addServiceToWatchlist(service: any): void {
      this.servicesWatchlist.push(service);
    },
    resetWatchlist(): void {
      this.servicesWatchlist = [];
    },
    setSignedUpUser(response: { [p: string]: any }): void {
      this.signedUpUser = response.user.username;
    },
    resetSignedUpUser(): void {
      this.signedUpUser = null;
    },
    changePWResponse(response: string | null): void {
      this.changePassword = response;
    },
    setUser(user: { [p: string]: any } | null): void {
      if (user === null) {
        this.attributes = null;
      } else {
        this.attributes = {
          type: user['attributes']['custom:userType'],
          email: user['attributes']['email'],
          email_verified: user['attributes']['email_verified'],
          sub: user['attributes']['sub'],
        };
      }
    },
    async signup(email: string, password: string, userType: string, regType: string): Promise<void> {
      this.signupError = '';
      const attr: {} = {
        'custom:userType': userType,
        email: email,
        'custom:regType': regType,
      };

      try {
        const response = await Auth.signUp({
          username: email.toLowerCase(),
          password: password,
          attributes: attr,
          validationData: [],
        });
        this.setSignedUpUser(response);
      } catch (error) {
        if (error) {
          // @ts-ignore
          this.signupError = error['message'] || error;
        }
      }
    },
    async logout() {
      localStorage.removeItem(ID_TOKEN);
      this.removeIdToken();
      useProfileDataStore().removeProfile();
      this.setUserType(null);
      this.setUser(null);
      this.resetWatchlist();
      useServiceStore().cacheTabindex(0);
      await Auth.signOut();
      this.signOut();
    },
  },
  persist: {
    key: 'arztpool24.de-user',
    debug: Debug.isEnabled(),
  },
});

export default useUserDataStore;
