export default {
  META: {
    TITLE: 'Impressum',
  },
  HEADING: "Impressum {'|'} arztpool 365 GmbH",
  GENERAL: {
    HEADING: 'Angaben gemäß § 5 TMG',
    TEXT: [
      'arztpool 365 GmbH',
      'Frankfurter Allee 31a',
      '10247 Berlin',
      'Geschäftsführung: Prof. Dr. Cai-Nicolas Ziegler (Vors.), Silke Oltrogge, Torsten Blaschke',
      'Telefon: 030 47 37 535 0',
      'Fax: 030 47 37 535 20',
      'E-Mail:',
    ],
  },
  REGISTER: {
    HEADING: 'Registereintrag',
    TEXT: [
      'Handelsregister: HRB 187459',
      'Registergericht: Amtsgericht Berlin (Charlottenburg)',
    ],
  },
  TAX: {
    HEADING: 'Umsatzsteuer-ID',
    TEXT: 'Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE287244264',
  },
  CREDITS: {
    HEADING: 'Credits',
    TEXT: [
      'Fotokonzept & Realisierung (Imagebilder)',
      'Kathleen Friedrich',
    ],
  },
  ONLINE_CONTENT: {
    HEADING: 'Inhalt des Onlineangebotes',
    TEXT: 'Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der  bereitgestellten Informationen. Haftungsansprüche gegen den Autor, die sich auf Schäden materieller oder  ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges  Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.',
  },
  LINKS: {
    HEADING: 'Verlinkungen',
    TEXT: "Bei direkten oder indirekten Verweisen auf fremde Internetseiten ('Links'), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.",
  },
  CONTENT: {
    HEADING: 'Inhalte',
    TEXT: 'Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf  den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller gelinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder unvollständige Inhalte und  insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über  Links auf die jeweilige Veröffentlichung lediglich verweist.',
  },
  COPYRIGHT: {
    HEADING: 'Urheberrecht',
    TEXT: 'Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente,  Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.',
  },
  DISCLAIMER: {
    HEADING: 'Rechtswirksamkeit dieses Haftungsausschlusses',
    TEXT: 'Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht,  nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.',
  },
  DISPUTE: {
    HEADING: 'Streitschlichtung',
    TEXT: [
      'Information zur Verbraucherstreitbeilegung nach § 36 VSBG',
      'Die arztpool 365 GmbH erklärt sich bei rechtlichen Konflikten mit Verbrauchern bereit, an einem Verbraucherschlichtungsverfahren nach dem Verbraucherstreitbeilegungsgesetz teilzunehmen.',
      'Die zuständige Schlichtungsstelle ist die',
      'Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V.',
      'Straßburger Straße 8',
      '77694 Kehl am Rhein',
      'Telefon 07851/7957940',
      'Fax 07851/7957941',
      'Verbraucherinformationen gemäß Verordnung (EU) Nr. 524/2013:',
      'Im Rahmen der Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten steht unter',
      ' eine Online-Streitbeilegungsplattform der EU-Kommission zur Verfügung.',
    ],
  },
  LIABILITY: {
    HEADING: 'Haftung',
    TEXT: [
      'Der Inhalt unserer Webseite wurde sorgfältig bearbeitet und geprüft. Gleichwohl wird für die Aktualität, Vollständigkeit und Richtigkeit keine Gewähr übernommen. Diese Webseite enthält ggf. externe Links zu Webseiten Dritter. Trotz sorgfältiger inhaltlicher Kontrolle unserer Webseite übernehmen wir keine Verantwortung und Haftung für die Inhalte externer Links. Für die Inhalte der verlinkten Seiten sind ausschließlich deren Anbieter verantwortlich.',
      'Die Inhalte dieser Seite und Folgenseiten sowie deren Gestaltung sind geistiges Eigentum von arztpool 365 GmbH oder der jeweils namentlich genannten natürlichen und/oder juristischen Personen und unterliegen dem Urheberrechtsschutz. Das Herunterladen (Downloads) von Inhalten ohne ausdrückliche schriftliche Zustimmung des jeweiligen Urhebers ist ausschließlich zum persönlichen, privaten und nicht kommerziellen  Gebrauch gestattet. In keinem Fall haftet die arztpool 365 GmbH für die Richtigkeit, Vollständigkeit  und/oder Aktualität der auf unserer Webseite zur Verfügung gestellten Inhalte.',
    ],
  },
};
