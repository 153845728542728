export default {
  META: {
    TITLE: 'Imprint',
  },
  HEADING: "Imprint {'|'} arztpool 365 GmbH",
  GENERAL: {
    HEADING: 'Information according to § 5 TMG',
    TEXT: [
      'arztpool 365 GmbH',
      'Frankfurter Allee 31a',
      '10247 Berlin',
      'Management: Prof. Dr. Cai-Nicolas Ziegler (Vors.), Silke Oltrogge, Torsten Blaschke',
      'Phone: 030 47 37 535 0',
      'Fax: 030 47 37 535 20',
      'E-Mail:',
    ],
  },
  REGISTER: {
    HEADING: 'Register entry',
    TEXT: [
      'Commercial Register: HRB 187459',
      'Registry Court: Amtsgericht Berlin (Charlottenburg)',
    ],
  },
  TAX: {
    HEADING: 'Sales tax ID',
    TEXT: 'Sales tax identification number according to §27 a Sales Tax Act: DE287244264',
  },
  CREDITS: {
    HEADING: 'Credits',
    TEXT: [
      'Webdesign',
      'Kathi Soika',
      'Photo concept & realization (Imageoictures)',
      'Kathleen Friedrich',
      'Programming',
      'Home Page:',
      'Martin Klinge',
      'Members-Area:',
      'Dennis Otrombowsky',
      'arztpool',
      'Robert Schmidl',
    ],
  },
  ONLINE_CONTENT: {
    HEADING: 'Content of this Onlineservice',
    TEXT: 'The author assumes no liability for the topicality, correctness, completeness or quality of the information provided. Liability claims against the author, which refer to material or immaterial nature caused by use or disuse of the information or the use of incorrect and incomplete information are excluded, unless the author is not intentional or grossly negligent fault. All offers are subject to change and non-binding. The author expressly reserves the right to change, supplement or delete parts of the pages or the entire offer without prior notice or to discontinue the publication temporarily or permanently.',
  },
  LINKS: {
    HEADING: 'Links',
    TEXT: 'The author is not responsible for any contents linked or referred to from his pages - unless he has full knowledge of illegal contents and would be able to prevent the visitors of his site fromviewing those pages.',
  },
  CONTENT: {
    HEADING: 'Contents',
    TEXT: "The author hereby expressly declares that at the time of linking, no illegal content was recognizable on the linked pages. The author has no influence on the current and future design, content or authorship of the linked pages. Therefore, he hereby expressly dissociates himself from all contents of all linked pages that were changed after the link was set. This statement applies to all links and references set within the author's own Internet offer as well as to external entries in guest books, discussion forums and mailing lists set up by the author. For illegal, incorrect or incomplete contents and especially for damages resulting from the use or non-use of such information, only the provider of the linked page is liable, not the one who has linked to the respective publication.",
  },
  COPYRIGHT: {
    HEADING: 'Copyright',
    TEXT: "The author endeavors to observe the copyrights of the graphics, sound documents, video sequences and texts used in all publications, to use graphics, sound documents, video sequences and texts created by himself or to use license-free graphics, sound documents, video sequences and texts. The copyright for any material created by the author is reserved. Any duplication or use of objects such as diagrams, sounds or texts in other electronic or printed publications is not permitted without the author's agreement.",
  },
  DISCLAIMER: {
    HEADING: 'Legal validity of this disclaimer',
    TEXT: 'This disclaimer is to be regarded as part of the internet publication which you were referred from. If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact.',
  },
  DISPUTE: {
    HEADING: 'Dispute Settlement',
    TEXT: [
      'Information on consumer dispute resolution according to § 36 VSBG',
      'Die arztpool 365 GmbH erklärt sich bei rechtlichen Konflikten mit Verbrauchern bereit, an einem Verbraucherschlichtungsverfahren nach dem Verbraucherstreitbeilegungsgesetz teilzunehmen.',
      'The competent arbitration body is the',
      'Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V.',
      'Straßburger Straße 8',
      '77694 Kehl am Rhein',
      'Phone 07851/7957940',
      'Fax 07851/7957941',
      'Consumer information in accordance with Regulation (EU) No. 524/2013:',
      'Within the framework of the Regulation on Online Dispute Resolution in Consumer Matters is available at',
      'an online dispute resolution platform of the EU Commission is available.',
    ],
  },
  LIABILITY: {
    HEADING: 'Liability',
    TEXT: [
      'The content of our website has been carefully edited and checked. Nevertheless, no responsibility is taken for the topicality, completeness and correctness. This website may contain external links to third-party websites. Despite careful control of the content of our website, we assume no responsibility or liability for the content of external links. For the content of the linked pages are solely responsible for their providers.',
      'The contents of this page and subsequent pages as well as their design are the intellectual property of arztpool 365 GmbH or the natural persons and/or legal entities named in each case and are subject to copyright protection. The downloading of content without the express written consent of the respective author is permitted exclusively for personal, private and non-commercial use. In no event shall arztpool 365 GmbH be liable for the accuracy, completeness and/or timeliness of the content provided on our website.',
    ],
  },
};
