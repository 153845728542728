export default {
  META: {
    TITLE: 'Seite nicht gefunden',
  },
  HEADING: 'Ihre angefragte Seite konnte nicht gefunden werden',
  HEADING_SUB: 'Das sollte nicht passieren ...',
  HEADING_BANNER: 'Seite nicht gefunden',
  BUTTON_TO_HOME: 'Zurück zur Startseite',
  DESCRIPTION: [
    'Leider konnten wir die angefragte Seite nicht finden. Bitte überprüfen Sie die Adresse in der Adresszeile Ihres Browsers. Sollte das Problem weiterhin bestehen bleiben sprechen Sie uns gerne an.',
  ],
};
