export default {
  HEADING: 'Dashboard',
  SUBHEADING: 'Herzlich willkommen {greeting}',
  SUBHEADING_NO_GREETING: 'Herzlich willkommen',
  MORE: 'Mehr Optionen',
  LOADING: 'Wir laden Ihre Dienste...',
  NO_SERVICES: 'Es wurden keine Dienste gefunden',
  DASHBOARD_LINKS: {
    TITLE: 'Was möchten Sie als nächstes tun?',
    PARTNER: {
      SERVICES: 'Dienste',
      ADD_SERVICES: 'Neue Dienste\neintragen',
      ASSIGNED_SERVICES: 'Vergebene\nDienste',
      MESSAGES: 'Nachrichten',
      CONDITIONS: 'Konditionen',
    },
    SUBSTITUTE: {
      SEARCH_SERVICES: 'Dienste\nsuchen',
      ASSIGNED_SERVICES: 'Zugewiesene\nDienste',
      MESSAGES: 'Nachrichten',
    },
    PROFILE: 'Meine Daten',
    OPERATOR: {
      MANAGE_PLATFORM: 'Plattform verwalten',
    },
    PROFILE_COMPLETENESS: {
      TEXT: 'Ihr Profil benötigt Ihre Aufmerksamkeit. Bitte vervollständigen Sie Ihre Daten',
      LINK: 'hier',
    },
  },
  FOCUS_SERVICES: {
    TITLE: 'Folgende Dienste benötigen Ihre Aufmerksamkeit:',
    RIBBON: {
      PARTNER: {
        CONTRACT_NOT_CONFIRMED: 'Vertrag noch nicht bestätigt',
        APPLICANTS: 'Dieser Dienst hat Bewerber',
        EMERGENCY_NOTES: 'Notfallscheine bestätigen',
      },
      SUBSTITUTE: {
        CONTRACT_NOT_CONFIRMED: 'Vertrag noch nicht bestätigt',
        PATIENTS_COUNT: 'Patientenanzahlen eintragen',
      },
    },
    LINK: 'Alle sehen',
  },
  LAST_SERVICES: {
    TITLE: 'Ihre Dienste',
    SERVICES_LINK: {
      PARTNERS: 'Neuen Dienst eintragen',
      SUBSTITUTES: 'Neuen Dienst suchen',
    },
  },
};
