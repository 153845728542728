export default {
  CLOSE: 'Schließen',
  HEADING: 'Passwort zurücksetzen',
  INITIAL_TEXT:
    'Bitte geben Sie die E-Mail Adresse ein, mit der Sie sich bei uns registriert haben. Wir schicken an diese E-Mail Adresse die Anleitung zum Zurücksetzen Ihres Passworts.',
  FORM: {
    MAIL: 'E-Mail',
    SUBMIT: 'Passwort zurücksetzen',
  },
  ERROR: {
    EXPIREDCODEEXCEPTION: 'Dieser Code ist abgelaufen - bitte fordern Sie das Zurücksetzen Ihres Passworts erneut an.',
    LIMITEXCEEDEDEXCEPTION: 'Die maximale Anzahl an Versuchen, das Passwort zurückzusetzen wurde erreicht. Bitte versuchen Sie es später noch einmal.',
  },
  BACK_TO_LOGIN: 'Zurück zum Login',
};
