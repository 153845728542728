import { AgencyFees, FeeTier } from '@/data/fees';
import { $t } from '@/plugins/i18n';
import { ServiceSubstituteValidationRules } from '@/data/states';
import { SERVICE_INFORMATION, SERVICE_MESSAGES_SENT } from '@/data/gql/queries';

import apolloClient from '@/plugins/apollo';
import Validation from '@/services/Validation';

import Message from '@/definitions/interfaces/Message.i';
import Service from '@/definitions/interfaces/Service.i';
import Substitute from '@/definitions/interfaces/Substitute.i';
import ServiceFee from '@/definitions/interfaces/ServiceFee.i';
import ServiceBreakInput from '@/definitions/interfaces/ServiceBreakInput.i';
import ServiceInput from '@/definitions/interfaces/ServiceInput.i';
import ServiceSubstitute from '@/definitions/interfaces/ServiceSubstitute.i';
import ServiceInformation from '@/definitions/interfaces/ServiceInformation.i';
import ServiceStateValidationRule from '@/definitions/interfaces/ServiceSubstituteValidationRule.i';

class ServiceService {
  /**
   * get initial service input data object
   * @return {object}
   */
  public initServiceInput(): ServiceInput {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    return {
      comment: '',
      regularFeeRange: {} as ServiceFee,
      holidayFeeRange: {} as ServiceFee,
      start: today,
      end: today,
      kind: $t('DATA.SERVICES.0'),
      establishmentID: '',
      establishmentDoctorID: '',
      priority: false,
      allinclusive: false,
      instantAssign: true,
      kvShareRateMobile: 0,
      kvShareRateOffice: 0,
      state: '',
      discount: 0,
      break: {} as ServiceBreakInput,
    };
  }

  /**
   * load checklist data for given service
   * @param {string} serviceId
   * @return {Promise}
   */
  public async loadChecklist(serviceId: string): Promise<null | ServiceInformation> {
    const response: { data: { getServiceInformation: null | ServiceInformation } } = await apolloClient.defaultClient.query({
      query: SERVICE_INFORMATION,
      variables: {
        serviceID: serviceId,
      },
      fetchPolicy: 'no-cache',
    });

    return response.data.getServiceInformation;
  }

  /**
   * load messages for given service
   * @param {string} serviceId
   * @return {Promise}
   */
  public async loadMessages(serviceId: string): Promise<Message[]> {
    const response: { data: { sentMessagesList: null | Message[] } } = await apolloClient.defaultClient.query({
      query: SERVICE_MESSAGES_SENT,
      variables: {
        service: serviceId,
      },
      fetchPolicy: 'no-cache',
    });

    if (response.data.sentMessagesList) {
      return response.data.sentMessagesList.reverse();
    }

    return [];
  }

  /**
   * calculate service tier by start date per default for services that are created right now
   * pass creation date if needed for existing services
   * @param {Date} start
   * @return {FeeTier}
   */
  public calculateServiceTier(start: Date): FeeTier {
    let tier: number = 0;

    if (start) {
      const startDate: number = new Date(start).getTime();
      const now = Date.now();
      const diffTime: number = startDate - now;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      tier = diffDays <= 3 ? 3 : diffDays <= 14 ? 2 : 1;
    }

    return AgencyFees.filter((feeTier: FeeTier): boolean => feeTier.tier === tier)[0];
  }

  /**
   * validate if a substitute is allowed for a service
   * @param {Service} service
   * @param {Substitute|ServiceSubstitute} substitute
   * @return {boolean}
   */
  public validateServiceSubstitute(service: Service, substitute: Substitute | ServiceSubstitute | null): boolean {
    if (substitute === null) {
      return false;
    }

    const serviceState: string | undefined = service.serviceArea?.state;

    if (serviceState) {
      const serviceRules: ServiceStateValidationRule[] = ServiceSubstituteValidationRules[serviceState];

      if (serviceRules) {
        // false and string is both false
        return Validation.test(serviceRules, { service, substitute }) === true;
      }
    }
    return true;
  }
}

export default new ServiceService();
