import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import apiConfig from '@/configs/api';
import Debug from '@/utils/Debug';

declare global {
  interface Window {
    AwsRum?: AwsRum;
  }
}

const config: AwsRumConfig = {
  sessionSampleRate: 1,
  identityPoolId: apiConfig.cloudWatch.awsrumIdentityPoolId,
  endpoint: apiConfig.cloudWatch.awsrumEndpoint,
  telemetries: [
    'performance',
    'errors',
    'http',
  ],
  allowCookies: true,
  enableXRay: true,
};

let awsRum: AwsRum | undefined;

try {
  awsRum = new AwsRum(
    apiConfig.cloudWatch.awsrumApplicationId,
    apiConfig.cloudWatch.awsrumApplicationVersion,
    apiConfig.cloudWatch.awsrumApplicationRegion,
    config,
  );

  // Attach to window object
  window.AwsRum = awsRum;

  // Add debugging for recordPageView if it exists
  if (awsRum.recordPageView && typeof awsRum.recordPageView === 'function') {
    const originalRecordPageView = awsRum.recordPageView.bind(awsRum);
    awsRum.recordPageView = (payload: any) => {
      return originalRecordPageView(payload);
    };
  }

  // Add debugging for addEvent if it exists
  if (awsRum.addEvent && typeof awsRum.addEvent === 'function') {
    const originalAddEvent = awsRum.addEvent.bind(awsRum);
    awsRum.addEvent = (event: any) => {
      return originalAddEvent(event);
    };
  }
} catch (error) {
  Debug.toConsole(error);
}

export { awsRum };

export default {
  install: (app: any) => {
    app.config.globalProperties.$awsRum = awsRum;
  },
};
