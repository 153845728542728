export default {
  HEADING: 'Passwort setzen',
  DESCRIPTION:
    'Sollte Ihre E-Mail Adresse und der Aktivierungs-Code nicht vorausgefüllt sein, entnehmen Sie den Code bitte der E-Mail, die Sie bekommen haben und geben Sie beides in die Felder ein.',
  FORM: {
    EMAIL: 'E-Mail',
    CODE: 'Aktivierungs-Code',
    PASSWORD: 'Ihr Passwort',
    PASSWORD_CONFIRMATION: 'Passwort bestätigen',
    SUBMIT: 'Passwort setzen',
  },
  ERROR: {
    INCORRECT_USERNAME_OR_PASSWORD: 'Der Aktivierungs-Code konnte der eingegeben E-Mail nicht zugeordnet werden. Bitte überprüfen Sie beides auf Richtigkeit.',
  },
  BACK_TO_LOGIN: 'Zurück zum Login',
};
