import { defineStore, PiniaPluginContext } from 'pinia';
import { restoreDateProperty } from '@/plugins/pinia';

import Debug from '@/utils/Debug';
import Message from '@/definitions/interfaces/Message.i';

export interface MessagesState {
  messages: Message[];
  loadingMessages: boolean;
}

const useMessageStore = defineStore('Messages', {
  state: (): MessagesState => ({
    messages: [],
    loadingMessages: false,
  }),
  getters: {
    /**
     * get all messages from that are not confirmed
     * @return {array}
     */
    unconfirmedMessages: (state: MessagesState) => {
      const messages: Message[] = [];

      for (const message of state.messages) {
        if (!message.confirmedOn) {
          messages.push(message);
        }
      }

      return messages;
    },
  },
  actions: {
    /**
     * replace all messages in store
     * @param {array} messages
     * @return {void}
     */
    setMessages(messages: Message[]): void {
      if (messages.length > 0) {
        const newMessages: Message[] = [];

        for (const message of messages) {
          newMessages.push(message);
        }

        this.messages = newMessages;
      }
    },

    /**
     * reset partner store
     * @return {void}
     */
    resetMessagesStore(): void {
      this.messages = [];
      this.loadingMessages = false;
    },
  },
  persist: {
    key: 'arztpool24.de-messages',
    debug: Debug.isEnabled(),
    afterRestore: (ctx: PiniaPluginContext): void => {
      ctx.store.$state.messages.forEach((message: Message): void => {
        restoreDateProperty(message, 'createdOn');
        restoreDateProperty(message, 'confirmedOn');
      });
    },
  },
});

export default useMessageStore;
