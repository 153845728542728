import { defineStore } from 'pinia';
import Debug from '@/utils/Debug';

import Service from '@/definitions/interfaces/Service.i';
import { ServiceSubmission } from '@/definitions/interfaces/ServiceSubmission.i';
import Institution from '@/definitions/interfaces/Institution.i';
import Establishment from '@/definitions/interfaces/Establishment.i';
import Debtor from '@/definitions/interfaces/Debtor.i';
import ServiceBulkEntry from '@/definitions/interfaces/ServiceBulk.i';

export interface PartnerDataState {
  services: Service[];
  focusServices: Service[];
  serviceSubmissions: ServiceSubmission[];
  institutions: Institution[];
  establishments: Establishment[];
  debtor: Debtor | null;
  newBulkServices: ServiceBulkEntry[];
  loadingMobileServicesPartner: boolean;
  loadingOfficeServicesPartner: boolean;
  loadingServiceSubmissions: boolean;
  loadingPartner: boolean;
  loadingInstitutions: boolean;
  loadingEstablishments: boolean;
  sortingPartnerServices: boolean;
  generatingPartnerFocusServices: boolean;
  upgradeConditionSelected: boolean;
}

const usePartnerDataStore = defineStore('PartnerData', {
  /**
    Define defaults

    loadingServicesPartner is set to true as a convenience as after a login this is the first thing that happens
    anyway - if you would like to set it to false to have a well-defined state after initializing, this needs to
    reflected when loading the services from the views and be set to true then
  */
  state: (): PartnerDataState => ({
    services: [],
    focusServices: [],
    serviceSubmissions: [],
    institutions: [],
    establishments: [],
    debtor: null,
    newBulkServices: [],
    loadingMobileServicesPartner: false,
    loadingOfficeServicesPartner: false,
    loadingServiceSubmissions: false,
    loadingPartner: false,
    loadingInstitutions: false,
    loadingEstablishments: false,
    sortingPartnerServices: false,
    generatingPartnerFocusServices: false,
    upgradeConditionSelected: false,
  }),
  actions: {
    /**
     * adds am array of establishments to the existing set of stored establishments
     * @param {array} newEstablishments
     * @return {void}
     */
    addEstablishments(newEstablishments: Establishment[]): void {
      for (const establishment of newEstablishments) {
        const _est = this.establishments.find((e: Establishment) => e.id === establishment.id);
        if (typeof _est === 'undefined') {
          this.establishments.push(establishment);
        }
      }
    },

    /**
     * reset partner store
     * @return {void}
     */
    resetPartnerStore(): void {
      this.services = [];
      this.focusServices = [];
      this.serviceSubmissions = [];
      this.institutions = [];
      this.establishments = [];
      this.debtor = null;
      this.loadingMobileServicesPartner = false;
      this.loadingOfficeServicesPartner = false;
      this.loadingPartner = false;
      this.loadingInstitutions = false;
      this.loadingEstablishments = false;
      this.sortingPartnerServices = false;
      this.generatingPartnerFocusServices = false;
      this.upgradeConditionSelected = false;
    },
  },
  persist: {
    key: 'arztpool24.de-partner',
    debug: Debug.isEnabled(),
  },
});

export default usePartnerDataStore;
