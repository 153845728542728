import Stores from '@/stores';
import Debug from '@/utils/Debug';
import EventBus from '@/services/EventBus';

class CaptureException {
  public async send(error: any) {
    const tags: { [p: string]: any[] } = {};
    tags['userEmail'] = Stores.userData.signedIn ? Stores.profileData.profile.email : 'guest';
    tags['userId'] = Stores.userData.signedIn ? Stores.profileData.profile.id : 'guest';
    tags['userRole'] = Stores.userData.signedIn ? Stores.profileData.profile.roles[0] : 'guest';

    const uri = window.location;
    const params = window.location.search.substring(0);
    EventBus.emit(EventBus.keys.OPERATORS_SHOW_EXCEPTION, error);
    try {
      // @ts-expect-error: Accessing private property eventCache
      if (window.AwsRum && typeof window.AwsRum.eventCache.addRecordToCache === 'function') {
        // @ts-expect-error: Accessing private property eventCache
        window.AwsRum.eventCache.addRecordToCache('ExceptionCapture', {
          exception: error,
          request: uri,
          params: params,
          tags,
        });
      }
    } catch (error) {
      Debug.captureException(error, tags);
    }
  }
}

export default new CaptureException();
