export default {
  META: {
    TITLE: 'Profil',
  },
  HEADING: 'Ihre Daten',
  ACCOUNT: {
    TITLE: 'Ihr Account',
    DESCRIPTION:
      'Vielen Dank für Ihr Vertrauen in arztpool - Hier finden Sie einen kurzen Überblick über Ihren Account. Bei Fragen oder Kontakt zu unserem Service halten Sie bitte Ihre Kundennummer bereit.',
    SUPPORT: 'Unseren Support erreichen Sie telefonisch unter der Nummer 030 47 37 535 0.',
    ACCOUNT_NUMBER: 'Kundennummer',
    COMPLETENESS: 'Vollständigkeit Ihres Accounts',
    COMPLETENESS_INFO: 'Folgende Informationen sind nicht vorhanden oder nicht vollständig:',
    COMPLETENESS_COMPLETE: 'Herzlichen Glückwunsch! Ihr Profil ist vollständig.',
    COMPLETENESS_REASONS: {
      BILLING_ADDRESS: 'Rechnungsadresse',
      BANK_ACCOUNT: 'Bankverbindung',
      TRAININGCONFIRMED: 'Bestätigung der ärztlichen Weiterbildung',
      LICENSE: 'Approbation',
      NO_INSURANCE: 'Keine Versicherung',
      NO_VALID_INSURANCE: 'Keine gültige Versicherung',
      INCOMPLETE_ESTABLISHMENT: 'Unvollständige Betriebsstätte',
      NO_ESTABLISHMENTS: 'Keine Betriebsstätte',
      NO_INSTITUTIONS: 'Keine Einrichtung',
      INCOMPLETE_INSTITUTION: 'Unvollständige Einrichtung',
      BASE_DATA: 'Stammdaten',
    },
  },
  BASE_DATA: {
    TITLE: 'Stammdaten',
    HEADING: 'Stammdaten',
    DESCRIPTION: 'Hier können Sie Ihre persönlichen Daten bearbeiten. Über diese Daten können wir Sie bei Fragen und Problemen erreichen.',
    LOADING_ERROR: 'Ihre Stammdaten konnten nicht geladen werden.',
    FORM: {
      TITLE: 'Titel',
      GENDER: 'Geschlecht',
      FIRSTNAME: 'Vorname',
      LASTNAME: 'Nachname',
      BIRTHDATE: 'Geburtsdatum',
      PHONE: 'Festnetz',
      MOBILE1: 'Mobiltelefon',
      MOBILE2: 'alternatives Mobiltelefon',
      FAX: 'Fax',
      SUBMIT_BUTTON: 'Speichern',
    },
  },
  PARTNER: {
    INSTITUTIONS: {
      TITLE: 'Einrichtung',
      HEADING: 'Ihre Einrichtung',
      DESCRIPTION:
        'Hier können Sie die Daten Ihrer Einrichtung bearbeiten. Über diese Daten laufen die Abrechnungen für Ihre vermittelten Dienste. Die Daten für die Betriebsstätten, für die Sie Ihre konkreten Dienste eintragen, finden Sie unter "Betriebsstätten".',
      SUBHEADING_INSTITUTION_MVZ: 'Ihr MVZ',
      SUBHEADING_INSTITUTION_SINGLE: 'Ihre Praxis',
      SUBHEADING_BILLING_ADDRESS: 'Rechnungsadresse',
      SUBHEADING_BANK: 'Bankverbindung',
      LOADING_ERROR: 'Ihre Einrichtung konnte nicht geladen werden.',
      FORM: {
        NAME: 'Einrichtungsname',
        BSNR: 'Betriebsstättennummer',
        STREET: 'Straße',
        HOUSENR: 'Hausnummer',
        ZIP: 'Postleitzahl',
        CITY: 'Ort',
        STATE: 'Bundesland',
        ACCOUNT_HOLDER: 'Kontoinhaber',
        IBAN: 'IBAN',
        BIC: 'BIC',
        BANK: 'Bank',
        SUBMIT_BUTTON: 'Speichern',
      },
    },
    ESTABLISHMENTS: {
      TITLE: 'Betriebsstätten',
      HEADING: 'Ihre Betriebsstätten',
      DESCRIPTION:
        'Hier können Sie die Daten Ihrer Bestriebsstätten verwalten. Dienste auf unserer Plattform werden für Ärzte in ihren Betriebsstätten eingetragen. Diese können Sie hier anlegen und verwalten.',
      SUBHEADING_ESTABLISHMENTS: 'Ihre Betriebsstätten',
      MANAGE_ESTABLISHMENTS: 'Mit einem Klick auf den Namen Ihrer Betriebsstätte können Sie diese "aufklappen" und alle wesentlichen Daten verwalten.',
      SUBHEADING_NEW_ESTABLISHMENT: 'Neue Betriebsstätte anlegen',
      SUBHEADING_BILLING_ADDRESS: 'Rechnungsadresse',
      SUBHEADING_ESTABLISHMENT_DOCTORS: 'Ärzte',
      ESTABLISHMENT_DOCTORS_DESCRIPTION:
        'Hier können Sie die Ärzte für diese Betriebsstätte verwalten. Dienste tragen Sie immer für die einzelnen Ärzte ein. Bitte legen Sie jeden Arzt/jede Ärztin, für die Sie Dienste eintragen wollen, hier an.',
      SUBHEADING_ESTABLISHMENT_SERVICEAREA: 'Dienstgebiet',
      ESTABLISHMENT_SERVICEAREA_DESCRIPTION:
        'Damit wir Ihnen beim Eintragen Ihrer Dienste die richtigen Auswahlmöglichkeiten in Ihrem Dienstgebiet zur Auswahl geben können, legen Sie hier bitte fest, in welchem Dienstgebiet sich diese Betriebsstätte befindet.',
      ESTABLISHMENT_NO_SERVICEAREA: 'Noch keines gewählt, bitte bearbeiten Sie dies.',
      ESTABLISHMENT_SERVICEAREA_CURRENT_AREA: 'Derzeitiges Dienstgebiet',
      ADD_NEW_DOCTOR: 'Arzt hinzufügen',
      LOADING: 'Wir laden Ihre Betriebsstätten.',
      LOADING_ERROR: 'Ihre Betriebsstätten konnten nicht geladen werden.',
      FORM: {
        NAME: 'Name der Betriebsstätte',
        BSNR: 'Betriebsstättennummer',
        STREET: 'Straße',
        HOUSENR: 'Hausnummer',
        ZIP: 'Postleitzahl',
        CITY: 'Ort',
        STATE: 'Bundesland',
        SUBMIT_BUTTON: 'Speichern',
        EDIT_BUTTON: 'Bearbeiten',
      },
    },
  },
  SUBSTITUTE: {
    BILLING: {
      TITLE: 'Abrechnungsdaten',
      HEADING: 'Ihre Abrechnungsdaten',
      DESCRIPTION:
        'Die folgenden Daten verwenden wir, um Ihre Rechnungsvorlagen und die Vertragsinformationen auszufüllen. Bitte achten Sie darauf, dass die folgenden Angaben richtig sind, da sie Vertragsbestandteil der Dienstvertretungsverträge werden.',
      SUBHEADING_ADDRESS: 'Rechnungsadresse',
      SUBHEADING_TAX: 'Steuernummer',
      SUBHEADING_BANK: 'Bankverbindung',
      LOADING_ERROR: 'Ihre Abrechnungsdaten konnten nicht geladen werden.',
      FORM: {
        NAME: 'Name der Betriebsstätte',
        BSNR: 'Betriebsstättennummer',
        STREET: 'Straße',
        HOUSENR: 'Hausnummer',
        ZIP: 'Postleitzahl',
        CITY: 'Ort',
        STATE: 'Bundesland',
        ACCOUNT_HOLDER: 'Kontoinhaber',
        IBAN: 'IBAN',
        BIC: 'BIC',
        BANK: 'Bank',
        TAXNUMBER: 'Steuernummer',
        SUBMIT_BUTTON: 'Speichern',
      },
    },
    INSURANCES: {
      TITLE: 'Versicherungen',
      HEADING: 'Ihre Versicherungen',
      DESCRIPTION:
        'Um Dienste vermittelt zu bekommen, benötigen Sie eine Versicherung, die zum Dienstantritt gültig ist. Sie können hier Versicherungsinformationen hinterlegen, mit denen wir sicherstellen können, dass Sie versichert sind.',
      SUBHEADING_INSURANCES: 'Ihre Versicherungen',
      SUBHEADING_NEW_INSURANCE: 'Neue Versicherung hinzufügen',
      MANAGE_INSURANCES: 'Mit einem Klick auf den Namen und die Nummer Ihrer Versicherung können Sie diese "aufklappen" und alle wesentlichen Daten einsehen.',
      VALID_FROM: 'Gültig von',
      VALID_TO: 'Gültig bis',
      DOWNLOAD: 'Police herunterladen',
      DOWNLOAD_BUTTON: 'Download',
      INSURANCE_NAME: 'Name der Versicherung',
      INSURANCE_NUMBER: 'Nummer der Police',
      FORM: {
        INSURANCE_NAME: 'Name der Versicherung',
        INSURANCE_NUMBER: 'Nummer der Police',
        VALID_FROM: 'Laufzeit von',
        VALID_TO: 'Laufzeit bis',
        DOCUMENT: 'Haftpflichtversicherungsurkunde',
        SUBMIT_BUTTON: 'Speichern',
        EDIT_BUTTON: 'Bearbeiten',
      },
    },
    SPECIALIZATION: {
      TITLE: 'Qualifikation/Ausstattung',
      HEADING: 'Qualifikation/Ausstattung',
      DESCRIPTION:
        'Hier können Sie alle Informationen bearbeiten, die Ihnen helfen über arztpool KV Dienste zu finden. Einige davon sind für einen besseren Austausch mit den Ärzten, die Sie vertreten, hilfreich, andere helfen uns, Sie besser für Dienste zu vermitteln.',
      SUBHEADING_SPECIALIZATION: 'Ihre Fachrichtung',
      SPECIALIZATION_DESCRIPTION:
        'Über Ihre Fachrichtung finden Sie passende KV Dienste. "Allgemeinärztliche Bereitschaftsdienste" können wir an jede hier angegebene Fachrichtung vermitteln, spezialisierte Fachrichtungen vermitteln wir nur an Vertreter, die uns hier eine entsprechende Spezialisierung mitteilen.',
      SPECIALIZATION_CERTIFICATE_UPLOAD: 'Bitte laden Sie hier, sofern vorhanden, Ihre Facharzturkunde hoch.',
      LICENSE_UPLOAD: 'Bitte laden Sie hier Ihre Approbation hoch.',
      SUBHEADING_DOCTORS_BAG: 'Arzttasche',
      DOCTORS_BAG_DESCRIPTION:
        'Für die Durchführung eines KV Dienstes empfehlen wir eine ausreichend bestückte Arzttasche. Hier können Sie den Ärzten, auf deren Dienste Sie sich bewerben mitteilen, dass Sie eine entsprechende Arzttasche besitzen.',
      SUBHEADING_BLACKLIST: 'Bundesländer',
      BLACKLIST_DESCRIPTION:
        'Von Zeit zu Zeit werden wir an ausgewählte Vertreter Ausschreibungs-Mails verschicken, um auf KV Dienste aufmerksam zu machen, die auf dem Marktplatz Vertreter brauchen. Für diese Ausschreibungs-Mails filtern wir die Empfänger nach den Bundesländern, in denen diese Dienste stattfinden. Hier könnnen Sie bestimmen, für welche Bundesländer Sie ggf. solche E-Mails empfangen wollen.',
      DOWNLOAD: 'Police herunterladen',
      DOWNLOAD_BUTTON: 'Download',
      GIVEN_SPECIALIZATION: 'Ihre momentan hinterlegte Fachrichtung',
      GIVEN_SPECIALIZATION_CERTIFICATE: 'Ihre momentan hinterlegte Facharzturkunde',
      GIVEN_LICENSE: 'Ihre momentan hinterlegte Approbation',
      GIVEN_CONFIRMATION: 'Sie haben uns bestätigt, dass Sie sich mind. im 3. Jahr Ihrer Weiterbildung befinden.',
      CONFIRM: 'Bestätigen',
      CONFIRMATION_TRAINING:
        'Hiermit bestätige ich, dass ich mich mindestens im dritten Jahr meiner ärztlichen Weiterbildung befinde oder diese bereits abgeschlossen habe',
      FORM: {
        SPECIALIZATION: 'Fachrichtung',
        DOCTORS_BAG: 'Arzttasche vorhanden',
        STATES: 'Bundesländer',
        DOCUMENT_LICENSE: 'Approbation',
        DOCUMENT_SPECIALIZATION: 'Facharzturkunde',
        SUBMIT_BUTTON: 'Speichern',
      },
    },
    MAILINGS: {
      TITLE: 'Benachrichtigungen',
      HEADING: 'Ihre Benachrichtigungs-Einstellungen',
      DESCRIPTION:
        'Während Sie auf arztpool.de angemeldet sind, werden wir Ihnen von Zeit zu Zeit verschiedenste E-Mails zuschicken. Hier können Sie einstellen, welche E-Mails Sie von uns bekommen möchten.',
      SUBHEADING_BLACKLIST: 'Benachrichtigungen über Dienste',
      BLACKLIST_DESCRIPTION:
        'Für bestimmte Dienste versenden unsere Mitarbeiter an ausgewählte Vertreter E-Mails mit der Chance, sich auf diese direkt bewerben zu können. Hier können Sie einstellen, für welche Bundesländer Sie solche E-Mails bekommen wollen und für welche nicht.',
      FORM: {
        SUBMIT_BUTTON: 'Speichern',
      },
    },
  },
  NO_RESULTS: 'Keine Einträge',
  FILTER: {
    ALL: 'Alle',
  },
  SORTING: 'Sortierung',
  SORT: {
    ASC: 'Aufsteigend',
    DESC: 'Absteigend',
  },
};
