import { $t } from '@/plugins/i18n';
import { ServiceMobileObject, ServiceOfficeObject } from '@/definitions/interfaces/ServiceObject.i';
import {
  QUERY_INSTITUTIONS,
  QUERY_INSTITUTIONS_WITH_SUBMISSIONS,
  QUERY_SERVICE_SERVICEMANAGEMENT_PARTNER,
  QUERY_SERVICE_SUSBTITUTE_DOCUMENTLINKS_PARTNER,
} from '@/data/gql/queries';
import { MOBILE_SERVICES_QUERY, OFFICE_SERVICES_QUERY } from '@/data/gql/misc_queries';
import {
  ADD_ESTABLISHMENT_DOCTOR,
  ADD_PAYMENT_METHOD_BILL,
  CREATE_ESTABLISHMENTS,
  DELETE_ESTABLISHMENT_DOCTOR,
  REQUEST_PARTNER,
  SET_PARTNER_TARIFF,
  SUBMIT_MOBILESERVICE,
  SUBMIT_OFFICESERVICE,
  SUBMIT_TEMPORARY_SERVICE,
  UPDATE_ADDRESSES,
  UPDATE_BANKACCOUNTS,
  UPDATE_ESTABLISHMENT_DOCTOR,
  UPDATE_ESTABLISHMENTS,
  UPDATE_INSTITUTIONS,
  UPDATE_SERVICE,
} from '@/data/gql/mutations';

import apolloClient from '@/plugins/apollo';
import Stores from '@/stores';
import EventBus from '@/services/EventBus';
import Sort from '@/utils/Sort';
import ObjectReplace from '@/utils/ObjectReplace';
import Service from '@/definitions/interfaces/Service.i';
import Establishment from '@/definitions/interfaces/Establishment.i';
import EstablishmentInput from '@/definitions/interfaces/EstablishmentInput.i';
import EstablishmentDoctor from '@/definitions/interfaces/EstablishmentDoctor.i';
import Institution from '@/definitions/interfaces/Institution.i';
import InstitutionInput from '@/definitions/interfaces/InstitutionInput.i';
import Address from '@/definitions/interfaces/Address.i';
import BankAccount from '@/definitions/interfaces/BankAccount.i';
import ServiceInput from '@/definitions/interfaces/ServiceInput.i';
import { ServiceSubmission, TempServiceData } from '@/definitions/interfaces/ServiceSubmission.i';
import ProfileCompleteness from '@/definitions/interfaces/ProfileCompletenessScore.i';
import ServiceManagementData from '@/definitions/interfaces/ServiceManagementData.i';
import { ApolloQueryResult } from '@apollo/client';

class Partner {
  /**
   * fetch partner data set
   * @return {void}
   */
  public fetchPartner(): void {
    this.fetchInstitutions(true);

    EventBus.on(EventBus.keys.PARTNER_LOAD_MOBILE_SERVICES_COMPLETE, (): void => {
      if (Stores.partnerData.loadingOfficeServicesPartner === false) {
        this.sortServices();
      }
    });

    EventBus.on(EventBus.keys.PARTNER_LOAD_OFFICE_SERVICES_COMPLETE, (): void => {
      if (Stores.partnerData.loadingMobileServicesPartner === false) {
        this.sortServices();
      }
    });
  }

  /**
   * while setting up the account we might have set some placeholders;
   * this method cleans those from the profile, so the user doesn't see them
   * @return {Promise}
   */
  async filterPlaceholders(): Promise<void> {
    if (Stores.partnerData.debtor !== null) {
      for (const key of Object.keys(Stores.partnerData.debtor.billingAddress)) {
        if (Stores.partnerData.debtor.billingAddress[key] === '####PLACEHOLDER####' || Stores.partnerData.debtor.billingAddress[key] === '0') {
          Stores.partnerData.debtor.billingAddress[key] = null;
        }
      }
    }

    if (Stores.partnerData.institutions.length > 0) {
      if (Stores.partnerData.institutions[0].debtor !== null) {
        for (const key of Object.keys(Stores.partnerData.institutions[0].debtor.billingAddress)) {
          if (
            Stores.partnerData.institutions[0].debtor.billingAddress[key] === '####PLACEHOLDER####' ||
            Stores.partnerData.institutions[0].debtor.billingAddress[key] === '0'
          ) {
            Stores.partnerData.institutions[0].debtor.billingAddress[key] = null;
          }
        }
      }

      for (const key of Object.keys(Stores.partnerData.institutions[0])) {
        if (Stores.partnerData.institutions[0][key] === '####PLACEHOLDER####' || Stores.partnerData.institutions[0][key] === '0') {
          Stores.partnerData.institutions[0][key] = null;
        }
      }

      for (const key of Object.keys(Stores.partnerData.institutions[0].address)) {
        if (Stores.partnerData.institutions[0].address[key] === '####PLACEHOLDER####' || Stores.partnerData.institutions[0].address[key] === '0') {
          Stores.partnerData.institutions[0].address[key] = null;
        }
      }
    }

    if (Stores.partnerData.establishments.length > 0) {
      Stores.partnerData.establishments.forEach(function (establishment: any, index: number): void {
        for (const key of Object.keys(establishment)) {
          if (establishment[key] === '####PLACEHOLDER####' || establishment[key] === '0') {
            Stores.partnerData.establishments[index][key] = null;
          }
        }

        for (const key of Object.keys(establishment.billingAddress)) {
          if (establishment.billingAddress[key] === '####PLACEHOLDER####' || establishment.billingAddress[key] === '0') {
            Stores.partnerData.establishments[index].billingAddress[key] = null;
          }
        }
      });
    }
  }

  /**
   * calculate the profile completness and return attributes to complete
   * @return {Promise}
   */
  getProfileCompleteness(): ProfileCompleteness {
    const _score: ProfileCompleteness = {
      score: 100,
      reasons: [],
    };
    for (const key of Object.keys(Stores.profileData.profile)) {
      const fieldsToConsider = [
        'birthDate',
        'gender',
        'firstName',
        'lastName',
        'mobile1',
        'phone',
        'title',
      ];
      if (fieldsToConsider.some((field) => field === key) && Stores.profileData.profile[key] === null) {
        _score.score -= 10;
        _score.reasons.push({ label: 'BASE_DATA', route: '/profile/basedata' });
        break;
      }
    }

    if (Stores.partnerData.debtor !== null) {
      for (const key of Object.keys(Stores.partnerData.debtor.billingAddress)) {
        if (Stores.partnerData.debtor.billingAddress[key] === null) {
          _score.score -= 10;
          _score.reasons.push({ label: 'BILLING_ADDRESS', route: '/profile/institutions' });
          break;
        }
      }
    }

    if (Stores.partnerData.institutions.length > 0) {
      let hasIncompleteInstitution = false;
      if (Stores.partnerData.institutions[0].debtor !== null) {
        for (const key of Object.keys(Stores.partnerData.institutions[0].debtor.billingAddress)) {
          if (Stores.partnerData.institutions[0].debtor.billingAddress[key] === null) {
            hasIncompleteInstitution = true;
            break;
          }
        }
      }

      for (const key of Object.keys(Stores.partnerData.institutions[0])) {
        if (Stores.partnerData.institutions[0][key] === null) {
          hasIncompleteInstitution = true;
          break;
        }
      }

      for (const key of Object.keys(Stores.partnerData.institutions[0].address)) {
        if (Stores.partnerData.institutions[0].address[key] === null) {
          hasIncompleteInstitution = true;
          break;
        }
      }
      if (Stores.partnerData.institutions[0].establishments.length === 0) {
        hasIncompleteInstitution = true;
      }
      if (hasIncompleteInstitution) {
        _score.score -= 5;
        _score.reasons.push({ label: 'INCOMPLETE_INSTITUTION', route: '/profile/institutions' });
      }
    } else {
      _score.score -= 10;
      _score.reasons.push({ label: 'NO_INSTITUTIONS', route: '/profile/institutions' });
    }

    if (Stores.partnerData.establishments.length > 0) {
      let hasIncompleteEstablishment = false;
      Stores.partnerData.establishments.forEach(function (establishment: any, index: number): void {
        for (const key of Object.keys(establishment)) {
          if (Stores.partnerData.establishments[index][key] === null) {
            hasIncompleteEstablishment = true;
            break;
          }
        }

        for (const key of Object.keys(establishment.billingAddress)) {
          if (Stores.partnerData.establishments[index].billingAddress[key] === null) {
            hasIncompleteEstablishment = true;
            break;
          }
        }
        if (Stores.partnerData.establishments[index].doctors.length === 0 || Stores.partnerData.establishments[index].serviceArea === null) {
          hasIncompleteEstablishment = true;
        }
      });
      if (hasIncompleteEstablishment) {
        _score.score -= 5;
        _score.reasons.push({ label: 'INCOMPLETE_ESTABLISHMENT', route: '/profile/establishments' });
      }
    } else {
      _score.score -= 10;
      _score.reasons.push({ label: 'NO_ESTABLISHMENTS', route: '/profile/establishments' });
    }
    if (_score.score < 0) {
      _score.score = 20;
    }
    return _score;
  }

  /**
   * returns an array of Services that need the focus of the partner user
   * services are taken from local Storage
   * @param {number} count - define how many services should be returned
   * @return {Array.<Service>}
   */
  public getFocusServices(count: number): Service[] {
    return Stores.partnerData.focusServices.slice(0, count);
  }

  /**
   * returns an array of Services from the local storage
   * @param {number} count - define how many services should be returned, set to null for all
   * @return {Array.<Service>}
   */
  public getLastServices(count: number): Service[] {
    const _start = Stores.partnerData.services.length - count;
    return Stores.partnerData.services.slice(_start, Stores.partnerData.services.length);
  }

  /**
   * TODO add call to backend, to store submission
   * @returns {any}
   */
  public async addServiceSubmission(details: ServiceInput, type: string, tempServiceData: TempServiceData): Promise<boolean> {
    const service = {
      tempServiceData: {
        tempServiceAreaName: tempServiceData.tempServiceAreaName,
        tempServiceAreaInformation: tempServiceData.tempServiceAreaInformation,
      },
      type: type,
      comment: details.comment,
      regularFeeRange: {
        min: details.regularFeeRange.min,
        max: details.regularFeeRange.max,
      },
      start: details.start,
      end: details.end,
      kind: details.kind,
      establishmentID: details.establishmentID,
      establishmentDoctorID: details.establishmentDoctorID,
    };

    await apolloClient.defaultClient
      .mutate({
        mutation: SUBMIT_TEMPORARY_SERVICE,
        variables: {
          establishmentID: details.establishmentID,
          content: JSON.stringify(service),
        },
      })
      .then((response) => {
        return response.data.submitServiceSubmission;
      });
    return false;
  }

  /**
   * add a new mobile services
   * @param {Array.<ServiceObject>} newServices - an array of services to add
   * @return {Promise.<boolean>}
   */
  public async addMobileServices(newServices: ServiceMobileObject[]): Promise<Array<string>> {
    let _services: string[] = [];
    const _response = await apolloClient.defaultClient.mutate({
      mutation: SUBMIT_MOBILESERVICE,
      variables: {
        services: newServices,
        totalCount: parseFloat(newServices.length as unknown as string),
        publish: true,
      },
      fetchPolicy: 'no-cache',
    });

    if (_response && _response.data.submitMobileServices) {
      _services = _response.data.submitMobileServices;
    }

    return _services;
  }

  /**
   * add a new office services
   * @param {Array.<ServiceObject>} newServices - an array of services to add
   * @return {Promise.<boolean>}
   */
  public async addOfficeServices(newServices: ServiceOfficeObject[]): Promise<Array<string>> {
    let _services: string[] = [];
    const _response = await apolloClient.defaultClient.mutate({
      mutation: SUBMIT_OFFICESERVICE,
      variables: {
        services: newServices,
        totalCount: parseFloat(newServices.length as unknown as string),
        publish: true,
      },
      fetchPolicy: 'no-cache',
    });

    if (_response && _response.data.submitOfficeServices) {
      _services = _response.data.submitOfficeServices;
    }

    return _services;
  }

  /**
   * get an array of Establishments from local storage
   * @return {Array.<Establishment>}
   */
  public getEstablishments(): Establishment[] {
    return Stores.partnerData.establishments;
  }

  /**
   * update the institution in the store and in the backend
   * @param {Institution} newInstitution
   * @return {Promise.<boolean>}
   */
  public async updateInstitution(newInstitution: Institution): Promise<boolean> {
    Stores.partnerData.institutions[0] = newInstitution;
    const _response = await apolloClient.defaultClient.mutate({
      mutation: UPDATE_INSTITUTIONS,
      variables: {
        institutions: [
          {
            id: Stores.partnerData.institutions[0].id,
            bsnr: Stores.partnerData.institutions[0].bsnr,
            name: Stores.partnerData.institutions[0].name,
            type: Stores.partnerData.institutions[0].type,
          },
        ],
      },
    });

    return _response.data.updateInstitution === 'succeeded';
  }

  /**
   * update the address in the backend
   * @param {Address} newAddress
   * @return {Promise.<boolean>}
   */
  public async updateAddress(newAddress: Address): Promise<boolean> {
    const _response = await apolloClient.defaultClient.mutate({
      mutation: UPDATE_ADDRESSES,
      variables: {
        addresses: [
          {
            id: newAddress.id,
            city: newAddress.city,
            street: newAddress.street,
            houseNr: newAddress.houseNr,
            zipCode: newAddress.zipCode,
            state: newAddress.state,
            country: newAddress.country,
          },
        ],
      },
    });

    return _response.data.updateAddresses;
  }

  /**
   * update the banking information in the store and in the backend
   * @param {BankAccount} newBankAccount
   * @return {Promise.<boolean>}
   */
  public async updateBankAccount(newBankAccount: BankAccount): Promise<boolean> {
    const _response = await apolloClient.defaultClient.mutate({
      mutation: UPDATE_BANKACCOUNTS,
      variables: {
        accounts: [
          {
            id: newBankAccount.id,
            accountHolder: newBankAccount.accountHolder,
            bank: newBankAccount.bank,
            iban: newBankAccount.iban,
            bic: newBankAccount.bic,
          },
        ],
      },
    });

    return _response.data.updateBankAccounts;
  }

  /**
   * add a new Establishment
   * @param {EstablishmentInput} newEstablishment
   * @return {Promise.<string>}
   */
  public async addEstablishment(newEstablishment: EstablishmentInput): Promise<string> {
    EventBus.emit(EventBus.keys.UPDATE_SETUP_STEP, 'SETUP_PARTNER_ESTABLISHMENT');

    const _v4_regex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
    const _response = await apolloClient.defaultClient.mutate({
      mutation: CREATE_ESTABLISHMENTS,
      variables: {
        establishments: [newEstablishment],
      },
    });

    if (_response.data.createEstablishments[0].match(_v4_regex)) {
      this.fetchInstitutions(false);
      return _response.data.createEstablishments[0];
    }

    return '';
  }

  /**
   * update the establishment in the store and in the backend
   * @param {Establishment} newEstablishment
   * @return {Promise.<boolean>}
   */
  public async updateEstablishment(newEstablishment: Establishment): Promise<boolean> {
    const _establishmentIndex: number = Stores.partnerData.establishments.findIndex((e: Establishment): boolean => e.id === newEstablishment.id);

    if (_establishmentIndex !== -1) {
      Stores.partnerData.establishments[_establishmentIndex] = newEstablishment;
      const _response = await apolloClient.defaultClient.mutate({
        mutation: UPDATE_ESTABLISHMENTS,
        variables: {
          establishments: [
            {
              id: Stores.partnerData.establishments[_establishmentIndex].id,
              name: Stores.partnerData.establishments[_establishmentIndex].name,
              bsnr: Stores.partnerData.establishments[_establishmentIndex].bsnr,
            },
          ],
        },
      });

      if (_response.data.updateEstablishments[0] === 'succeeded') {
        return await this.updateAddress(newEstablishment.billingAddress as unknown as Address);
      }
    }

    return false;
  }

  /**
   * Add a new establishment doctor
   * @param {string} name - the name of the new doctor
   * @param {string} lanr - the LANR of the new doctor
   * @param {number} kvSeats - the number of kvSeats of the new doctor
   * @param {string}  establishment_id - the id of the establishment the new doctor should be added to
   * @return {Promise.<boolean>}
   */
  public async addEstablishmentDoctor(name: string, lanr: string, kvSeats: number, establishment_id: string): Promise<boolean> {
    EventBus.emit(EventBus.keys.UPDATE_SETUP_STEP, 'SETUP_PARTNER_DOCTOR');

    const _response = await apolloClient.defaultClient.mutate({
      mutation: ADD_ESTABLISHMENT_DOCTOR,
      variables: {
        establishmentID: establishment_id,
        name: name,
        kvSeats: kvSeats,
        lanr: lanr,
      },
    });

    if (_response.data.addEstablishmentDoctor === true) {
      await this.fetchInstitutions(false);
    }

    return _response.data.addEstablishmentDoctor;
  }

  /**
   * update an existing doctor
   * @param {EstablishmentDoctor} doctor - the doctor to update
   * @param {string}  establishment_id - the id of the establishment the doctor should be updated in
   * @return {Promise.<boolean>}
   */
  public async updateEstablishmentDoctor(doctor: EstablishmentDoctor, establishment_id: string): Promise<boolean> {
    const _establishmentIndex: number = Stores.partnerData.establishments.findIndex((e: Establishment) => e.id === establishment_id);

    if (_establishmentIndex !== -1) {
      const _doctorIndex: number = Stores.partnerData.establishments[_establishmentIndex].doctors.findIndex((eD: EstablishmentDoctor) => eD.id === doctor.id);

      if (_doctorIndex !== -1) {
        // update local storage
        Stores.partnerData.establishments[_establishmentIndex].doctors[_doctorIndex] = doctor;

        // sync to DB
        const _response = await apolloClient.defaultClient.mutate({
          mutation: UPDATE_ESTABLISHMENT_DOCTOR,
          variables: {
            establishmentDoctorID: doctor.id,
            name: doctor.name,
            kvSeats: Number(doctor.kvSeats),
            lanr: doctor.lanr,
          },
        });
        return _response.data.updateEstablishmentDoctor;
      }
    }

    return false;
  }

  /**
   * delete an existing doctor
   * @param {string} doctor_id - the id of the doctor to delete
   * @param {string}  establishment_id - the id of the establishment the doctor should be deleted from
   * @return {Promise.<boolean>}
   */
  public async deleteEstablishmentDoctor(doctor_id: string, establishment_id: string): Promise<boolean> {
    const _establishmentIndex: number = Stores.partnerData.establishments.findIndex((e: Establishment) => e.id === establishment_id);

    if (_establishmentIndex !== -1) {
      const _doctorIndex: number = Stores.partnerData.establishments[_establishmentIndex].doctors.findIndex((eD: EstablishmentDoctor) => eD.id === doctor_id);

      if (_doctorIndex !== -1) {
        // update local storage
        Stores.partnerData.establishments[_establishmentIndex].doctors.splice(_doctorIndex, 1);

        // sync to DB
        const _response = await apolloClient.defaultClient.mutate({
          mutation: DELETE_ESTABLISHMENT_DOCTOR,
          variables: {
            establishmentDoctorID: doctor_id,
          },
        });
        return _response.data.deleteEstablishmentDoctor;
      }
    }

    return false;
  }

  /**
   * update the service area for an establishment
   * @param {string} serviceArea_id - the new service area id
   * @param {string}  establishment_id - the id of the establishment the service area should be updated in
   * @return {Promise.<boolean>}
   */
  public async updateEstablishmentServiceArea(serviceArea_id: string, establishment_id: string): Promise<boolean> {
    const _establishmentIndex: number = Stores.partnerData.establishments.findIndex((e: Establishment) => e.id === establishment_id);

    if (_establishmentIndex !== -1) {
      // sync to DB
      const _response = await apolloClient.defaultClient.mutate({
        mutation: UPDATE_ESTABLISHMENTS,
        variables: {
          establishments: [
            {
              id: establishment_id,
              serviceAreaID: serviceArea_id,
            },
          ],
        },
      });

      if (_response.data.updateEstablishments[0] === 'succeeded') {
        await this.fetchInstitutions(false);
        return true;
      }
    }

    return false;
  }

  /**
   * get an array of doctors for an establishment
   * @param {string} establishmentID - the ID of the establishment to get the doctors for (as UUID)
   * @return {Array.<Service>}
   */
  public getEstablishmentDoctors(establishmentID: string): EstablishmentDoctor[] {
    let _doctors = [];
    const _establishment = Stores.partnerData.establishments.find((e: Establishment): boolean => e.id === establishmentID);

    if (typeof _establishment !== 'undefined') {
      _doctors = _establishment.doctors;
    }

    _doctors.sort((doc_a: EstablishmentDoctor, doc_b: EstablishmentDoctor): number => {
      const fa = doc_a.name.toLowerCase(),
        fb = doc_b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }

      if (fa > fb) {
        return 1;
      }

      return 0;
    });

    return _doctors;
  }

  /**
   * get an array of published services
   * @param {number|null} count - number of returned services; null returns all
   * @return {Array.<Service>}
   */
  public getPublishedServices(count: number | null): Service[] {
    const services: Service[] = [];

    for (const service of Stores.partnerData.services) {
      if (service.published && !service.concluded) {
        services.push(service);
      }
    }

    const _count: number = count ? count : services.length;
    return services.slice(0, _count);
  }

  /**
   * get an array of assigned services
   * @param {number|null} count - number of returned services; null returns all
   * @return {Array.<Service>}
   */
  public getAssignedServices(count: number | null): Service[] {
    const services: Service[] = [];

    for (const service of Stores.partnerData.services) {
      if (service.substitute !== null && !service.concluded) {
        services.push(service);
      }
    }

    const _count: number = count ? count : services.length;
    return services.slice(0, _count);
  }

  /**
   * get an array of concluded services
   *
   * @param {number|null} count - number of returned services; null returns all
   * @return {Array.<Service>}
   */
  public getConcludedServices(count: number | null): Service[] {
    const services: Service[] = [];

    for (const service of Stores.partnerData.services) {
      if (service.concluded) {
        services.push(service);
      }
    }

    const _count: number = count ? count : services.length;
    return services.slice(0, _count);
  }

  /**
   * Request a partner role from the backend
   * @param {InstitutionInput} institution
   * @return {Promise.<boolean>}
   */
  public async requestPartnerRole(institution: InstitutionInput): Promise<boolean> {
    EventBus.emit(EventBus.keys.UPDATE_SETUP_STEP, 'SETUP_PARTNER');

    const _response = await apolloClient.defaultClient.mutate({
      mutation: REQUEST_PARTNER,
      variables: {
        institution: institution,
      },
    });

    if (_response.data.requestPartnerRole === 'granted') {
      await this.fetchInstitutions(false);
      return true;
    }

    return false;
  }

  /**
   * fetch a partner's institutions
   * @param {boolean} alsoFetchServices
   * @return {Promise}
   */
  async fetchInstitutions(alsoFetchServices: boolean): Promise<void> {
    Stores.partnerData.loadingInstitutions = true;
    Stores.partnerData.loadingServiceSubmissions = true;
    let _institutions: ApolloQueryResult<any>;
    if (alsoFetchServices) {
      _institutions = await apolloClient.defaultClient.query({
        query: QUERY_INSTITUTIONS_WITH_SUBMISSIONS,
        fetchPolicy: 'no-cache',
      });
    } else {
      _institutions = await apolloClient.defaultClient.query({
        query: QUERY_INSTITUTIONS,
        fetchPolicy: 'no-cache',
      });
    }

    Stores.partnerData.institutions = _institutions.data.profile.institutions;
    let _establishments: Establishment[] = [];

    for (const institution of _institutions.data.profile.institutions) {
      if (Stores.partnerData.debtor === null) {
        Stores.partnerData.debtor = institution.debtor;
      }

      _establishments = _establishments.concat(institution.establishments);
    }

    // sort and add
    Stores.partnerData.establishments = Sort.arraySortBy(_establishments, (establishment) => [establishment.name], 1);

    await this.filterPlaceholders();

    if (alsoFetchServices) {
      this.fetchMobileServices([]);
      this.fetchOfficeServices([]);
      // add service submissions to the store
      let _submissions: ServiceSubmission[] = [];
      Stores.partnerData.establishments.forEach((establishment: Establishment) => {
        _submissions = [
          ..._submissions,
          ...establishment.submissions,
        ];
      });
      _submissions.forEach((submission: ServiceSubmission) => {
        this.updateServiceSubmissionStore(submission);
      });
      Stores.partnerData.loadingServiceSubmissions = false;
    }

    Stores.partnerData.loadingInstitutions = false;
    EventBus.emit(EventBus.keys.PARTNER_LOAD_INSTITUTIONS_COMPLETE);
  }

  /**
   * fetch a partner's mobile services for their establishments
   * @param {Array.<string>} service_ids - only fetch services with these ids; if array is empty all services are fetched
   * @return {Promise}
   */
  public async fetchMobileServices(service_ids: string[]): Promise<void> {
    Stores.partnerData.loadingMobileServicesPartner = true;

    if (service_ids.length > 0) {
      const _where = { service_id: { in: service_ids } };
      const _response = await apolloClient.defaultClient.query({
        query: MOBILE_SERVICES_QUERY,
        variables: {
          where: _where,
        },
        fetchPolicy: 'no-cache',
      });

      for (const newService of _response.data.mobileServices) {
        // only push the new service to the store if it is not present yet
        const _service = Stores.partnerData.services.find((s: Service) => s.id === newService.details.id);

        if (typeof _service === 'undefined') {
          const _serviceReorder: Service = newService.details;
          _serviceReorder.type = newService.__typename;
          Stores.partnerData.services.push(newService.details);
        }
      }
    } else {
      for (const establishment of Stores.partnerData.establishments) {
        const _where = { service: { establishment_id: { equals: establishment.id } } };
        const _response = await apolloClient.defaultClient.query({
          query: MOBILE_SERVICES_QUERY,
          variables: {
            where: _where,
          },
          fetchPolicy: 'no-cache',
        });

        for (const newService of _response.data.mobileServices) {
          // only push the new service to the store if it is not present yet
          const _service = Stores.partnerData.services.find((s: Service) => s.id === newService.details.id);

          if (typeof _service === 'undefined') {
            const _serviceReorder: Service = newService.details;
            _serviceReorder.type = newService.__typename;
            Stores.partnerData.services.push(newService.details);
          }
        }
      }
    }
    this.sortServices();
    Stores.partnerData.loadingMobileServicesPartner = false;
    EventBus.emit(EventBus.keys.PARTNER_LOAD_MOBILE_SERVICES_COMPLETE);
  }

  /**
   * fetch a partner's office services for their establishments
   * @param {Array.<string>} service_ids - only fetch services with these ids; if array is empty all services are fetched
   * @return {Promise}
   */
  public async fetchOfficeServices(service_ids: string[]): Promise<void> {
    Stores.partnerData.loadingOfficeServicesPartner = true;

    if (service_ids.length > 0) {
      const _where = { service_id: { in: service_ids } };
      const _response = await apolloClient.defaultClient.query({
        query: OFFICE_SERVICES_QUERY,
        variables: {
          where: _where,
        },
        fetchPolicy: 'no-cache',
      });

      for (const newService of _response.data.officeServices) {
        // only push the new service to the store if it is not present yet
        const _service = Stores.partnerData.services.find((s: Service) => s.id === newService.details.id);

        if (typeof _service === 'undefined') {
          const _serviceReorder: Service = newService.details;
          _serviceReorder.type = newService.__typename;
          Stores.partnerData.services.push(newService.details);
        }
      }
    } else {
      for (const establishment of Stores.partnerData.establishments) {
        const _where = { service: { establishment_id: { equals: establishment.id } } };
        const _response = await apolloClient.defaultClient.query({
          query: OFFICE_SERVICES_QUERY,
          variables: {
            where: _where,
          },
          fetchPolicy: 'no-cache',
        });

        for (const newService of _response.data.officeServices) {
          // only push the new service to the store if it is not present yet
          const _service = Stores.partnerData.services.find((s: Service) => s.id === newService.details.id);

          if (typeof _service === 'undefined') {
            const _serviceReorder: Service = newService.details;
            _serviceReorder.type = newService.__typename;
            Stores.partnerData.services.push(newService.details);
          }
        }
      }
    }

    this.sortServices();
    Stores.partnerData.loadingOfficeServicesPartner = false;
    EventBus.emit(EventBus.keys.PARTNER_LOAD_OFFICE_SERVICES_COMPLETE);
  }

  /**
   * Add a debtor bill payment method
   * @return {Promise.<boolean>}
   */
  public async addBillPaymentMethod(): Promise<boolean> {
    EventBus.emit(EventBus.keys.UPDATE_SETUP_STEP, 'SETUP_PARTNER_TARIFF');

    if (Stores.partnerData.debtor !== null) {
      const _v4_regex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
      const _response = await apolloClient.defaultClient.mutate({
        mutation: ADD_PAYMENT_METHOD_BILL,
        variables: {
          debtorID: Stores.partnerData.debtor.id,
        },
      });

      if (_response.data.addDebtorBillPaymentMethod.match(_v4_regex)) {
        // if the user has no tariff, add one (legacy but for db consistency)
        if (Stores.partnerData.debtor.tariff === null) {
          await apolloClient.defaultClient.mutate({
            mutation: SET_PARTNER_TARIFF,
            variables: {
              debtorID: Stores.partnerData.debtor.id,
              period: 12,
              paymentPeriod: 12,
              paymentMethodID: _response.data.addDebtorBillPaymentMethod,
            },
          });
        }

        return true;
      }
    }

    return false;
  }

  /**
   * fetch the full set of information needed for the partner service management page
   * @param {string} serviceId - service UUID
   * @return {Promise.<Service>}
   */
  public async fetchServiceManagementService(serviceId: string): Promise<Service> {
    const response = await apolloClient.defaultClient.query({
      query: QUERY_SERVICE_SERVICEMANAGEMENT_PARTNER,
      variables: {
        serviceID: serviceId,
      },
      fetchPolicy: 'no-cache',
    });

    const service: Service = response.data.listServices[0].details;
    service.type = response.data.listServices[0].__typename;

    return service;
  }

  /**
   * fetch assigned substitute's presinged download URLs
   * @param {string} serviceId - service UUID
   * @param {string} type - which document type to return the URL for
   * @return {Promise.<string>} - the URL
   */
  public async fetchAssignedSubstituteDocumentURL(serviceId: string, documentType: string): Promise<string | null> {
    const response = await apolloClient.defaultClient.query({
      query: QUERY_SERVICE_SUSBTITUTE_DOCUMENTLINKS_PARTNER,
      variables: {
        serviceID: serviceId,
      },
      fetchPolicy: 'no-cache',
    });

    if (documentType === 'license') {
      return response.data.listServices[0].details.substitute?.details.license?.url;
    }

    if (documentType === 'liabilityInsurance') {
      return response.data.listServices[0].details.substitute?.liabilityInsurance?.url;
    }

    if (documentType === 'specializations') {
      return response.data.listServices[0].details.substitute?.specializations[0].certificate?.url;
    }

    return null;
  }

  /**
   * add service to local storage service list and sort
   * @author kerstin.reicherdt
   * @param {any} service:ServiceManagementData|Service
   * @param {boolean} complete  - replace the whole service entry in the local store? of no, just the available info will be replaced
   * @returns {any}
   */
  public updateServiceStore(updateService: ServiceManagementData | Service, complete: boolean): void {
    const _cacheIndex = Stores.partnerData.services.findIndex((service: Service) => service.id === updateService.id);
    if (_cacheIndex !== -1) {
      if (complete) {
        // replace the whole entry in the store
        Stores.partnerData.services[_cacheIndex] = updateService;
      } else {
        // iterate over service keys and update them in store if available
        const _result = ObjectReplace.recursiveReplace(Stores.partnerData.services[_cacheIndex], updateService);
        Stores.partnerData.services[_cacheIndex] = _result;
      }
    } else {
      Stores.partnerData.services.push(updateService);
    }
    this.sortServices();
  }

  /**
   * add service submission to local storage submissions list
   * @author kerstin.reicherdt
   * @param {any} service:ServiceSubmission
   * @returns {any}
   */
  public updateServiceSubmissionStore(updateServiceSubmission: ServiceSubmission): void {
    updateServiceSubmission.content = JSON.parse(updateServiceSubmission.content);
    const _cacheIndex = Stores.partnerData.serviceSubmissions.findIndex((submission: Service) => submission.id === updateServiceSubmission.id);
    if (_cacheIndex !== -1) {
      // replace the whole entry in the store
      Stores.partnerData.serviceSubmissions[_cacheIndex] = updateServiceSubmission;
    } else {
      Stores.partnerData.serviceSubmissions.push(updateServiceSubmission);
    }
  }

  /**
   * confirm a contract
   *
   */
  public async confirmServiceContract(service_id: string): Promise<boolean> {
    const _response = await apolloClient.defaultClient.mutate({
      mutation: UPDATE_SERVICE,
      variables: {
        serviceID: service_id,
        partnerConfirmedContract: true,
      },
    });
    return _response.data.updateService;
  }

  /**
   * Sort all services in local storage with start date ASC as a sort criteria
   * @return {void}
   */
  sortServices(): void {
    Stores.partnerData.sortingPartnerServices = true;
    Stores.partnerData.services = Sort.arraySortBy(Stores.partnerData.services, (service) => [service.start], 1);
    this.setServiceFocusReasons();
    Stores.partnerData.sortingPartnerServices = false;
  }

  /**
   * Sets possible focus reasons for all services present in the store
   * @return {void}
   */
  public setServiceFocusReasons(): void {
    Stores.partnerData.generatingPartnerFocusServices = true;

    const _now = new Date();
    Stores.partnerData.focusServices = [];
    for (const _service of Stores.partnerData.services) {
      const _serviceStart = new Date(_service.start);
      const _serviceEnd = new Date(_service.start);
      const _reasons = [];

      if (_serviceStart >= _now) {
        if (_service.applicants) {
          if (_service.applicants.length > 0 && _service.substitute === null) {
            _reasons.push($t('DASHBOARD.FOCUS_SERVICES.RIBBON.PARTNER.APPLICANTS'));
          }
          if (_service.substitute !== null && _service.partnerConfirmedContractOn === null) {
            _reasons.push($t('DASHBOARD.FOCUS_SERVICES.RIBBON.PARTNER.CONTRACT_NOT_CONFIRMED'));
          }
        }
      }
      if (_serviceEnd < _now) {
        if (_service.countsConfirmedOn === null && _service.substitute !== null) {
          _reasons.push($t('DASHBOARD.FOCUS_SERVICES.RIBBON.PARTNER.EMERGENCY_NOTES'));
        }
      }
      _service.focusReasons = _reasons;
      if (_reasons.length > 0) {
        Stores.partnerData.focusServices.push(_service);
      }
    }
    Stores.partnerData.focusServices = Sort.arraySortBy(Stores.partnerData.focusServices, (service) => [service.start], 1);
    Stores.partnerData.generatingPartnerFocusServices = false;
  }
}

export default new Partner();
