class Sort {
  /**
   * Sort an array of objects by any property of the containing objects
   *
   * Usage:
   * const _objects = [
   *  { name: "Alice", age: 30 },
   *  { name: "Bob", age: 25 },
   *  { name: "Charlie", age: 35 }
   * ];
   *
   * const sortedObjects = arraySortBy(_objects, function(obj) {
   *    return [obj.age]; // Sorting based on the 'age' property
   * });
   *
   *
   * @param {Array.<Object>} the array to sort
   * @param {function} the sort function
   * @param {number} the sort order (1 for ASC, -1 for DESC) (defaults to ASC)
   * @return {Array.<Object>}
   */
  public arraySortBy(arrayToSort: any[], sortingFunction: (o: any) => any[], sortOrder: number): any[] {
    sortOrder = sortOrder || 1; // default to ASC

    for (let i = arrayToSort.length; i; ) {
      const o = arrayToSort[--i];
      arrayToSort[i] = [
        ...(sortingFunction.call(o, o) as any[]),
        o,
      ];
    }

    arrayToSort.sort((a, b) => {
      for (let i = 0, len = a.length; i < len; ++i) {
        if (a[i] !== b[i]) {
          return (a[i] < b[i] ? -1 : 1) * sortOrder;
        }
      }
      return 0;
    });

    for (let i = arrayToSort.length; i > 0; ) {
      arrayToSort[--i] = arrayToSort[i][arrayToSort[i].length - 1];
    }

    return arrayToSort;
  }
}

export default new Sort();
