class ObjectReplace {
  /**
   * Recursively replace the values of the keys of object 1 with the values of the keys of the same name in object 2
   *
   * @param {Object} obj1 - the object the values are replaced in
   * @param {Object} obj2 - the object the values are replaced from
   * @return {Obecjt}
   */
  public recursiveReplace(obj1: Record<string, any>, obj2: Record<string, any>): Record<string, any> {
    for (const key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          // Recursive call for nested objects
          obj1[key] = this.recursiveReplace(obj1[key], obj2[key]);
        } else if (Object.prototype.hasOwnProperty.call(obj2, key)) {
          // Replace value if key exists in both objects
          obj1[key] = obj2[key];
        }
      }
    }
    return obj1;
  }
}

export default new ObjectReplace();
