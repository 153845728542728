export default {
  HEADING: 'Information zu Ihrer Vermittlungsgebühr',
  SUB_HEADING: [
    'Dieser Dienst ist ein Eil-Auftrag, weil Dienstbeginn in weniger als 15 Tagen ist.',
    'Dieser Dienst ist ein Express-Auftrag, weil Dienstbeginn in weniger als 3 Tagen ist.',
  ],
  OPTION: [
    {
      HEADING: 'Standard-Auftrag',
      TEXT: '15 - ∞ Tage Vorlauf',
      SMALL_TEXT:
        'Wir vermitteln Ihnen schnell und papierlos geprüfte Vertretungsärzte - Dienstvertretungsvertragsvorlage inklusive. Sie haben die Kontrolle über die Auswahl des Vertreters. Über Ihren persönlichen Zugang verwalten Sie Ihre Betriebsstätten, Ärzte und Dienste und können Statusinformationen einsehen. Bei Notfällen erreichen Sie uns 24/7.',
    },
    {
      HEADING: 'Eil-Auftrag',
      TEXT: '4 bis 14 Tage Vorlauf',
      SMALL_TEXT:
        'Sie erhalten alle Vorzüge des Standard-Auftrags. Zusätzlich stellen wir Ihnen einen persönlichen Ansprechpartner für Ihren KV-Dienst zu Seite. Damit Sie das Maximum herausholen, erhalten Sie eine Anleitung zur Optimierung Ihrer Inseraten mit Checkliste (Material,..)',
    },
    {
      HEADING: 'Express-Auftrag',
      TEXT: '3 Tage oder weniger Vorlauf',
      SMALL_TEXT:
        'Sie übergeben uns digital die Dienstdaten, wir kümmern uns den Rest: Dabei erhalten Sie alle Vorteile des Eil-Auftrags. Zudem organisieren wir für Sie die Bewerberanfragen im Rahmen Ihrer Honorarvorgaben.',
    },
  ],
  TERMS_TEXT: 'Ich bin einverstanden mit der Vermittlungsgebühr. Mir ist bewusst, dass diese erst nach Zuteilung des Dienstes fällig ist.',
  BUTTON: {
    CANCEL: 'Abbrechen',
    SUBMIT: 'KV-Dienst eintragen',
  },
};
