import meta from '@/locales/en/meta';
import data from '@/locales/en/data';
import validation from '@/locales/en/validation';

import navigation from '@/locales/en/navigation';
import footer from '@/locales/en/footer';

import imprint from '@/locales/en/views/imprint';

export default {
  // general
  META: meta,
  DATA: data,
  VALIDATION: validation,

  // components
  NAVIGATION: navigation,
  FOOTER: footer,

  // views
  IMPRINT: imprint,
};
