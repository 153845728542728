import { defineStore } from 'pinia';
import NavItem from '@/definitions/interfaces/NavItem.i';
import ServiceArea from '@/definitions/interfaces/ServiceArea.i';
import FullService from '@/definitions/interfaces/FullService.i';
import { ServiceSubmission } from '@/definitions/interfaces/ServiceSubmission.i';
import ServiceInformation from '@/definitions/interfaces/ServiceInformation.i';
import DataTableSortOptions from '@/definitions/interfaces/DataTableSortOptions.i';
import User from '@/definitions/interfaces/User.i';
import Debug from '@/utils/Debug';
import OperatorServiceCache from '@/definitions/interfaces/OperatorServiceCache.i';

export interface OperatorDataState {
  servicesViewCache: OperatorServiceCache;
  servicesCache: FullService[];
  servicesSortCache: DataTableSortOptions | null;
  allInclusiveServicesSortCache: DataTableSortOptions | null;
  tieredServicesSortCache: DataTableSortOptions | null;
  serviceSubmissionsSortCache: DataTableSortOptions | null;
  servicesTieredCache: FullService[];
  servicesAllInclusiveCache: FullService[];
  serviceSubmissionsCache: ServiceSubmission[];
  serviceAreasCache: ServiceArea[];
  serviceAreasSortCache: DataTableSortOptions | null;
  usersCache: User[];
  usersSortCache: DataTableSortOptions | null;
  fullUsersCache: User[];
  fullServicesCache: FullService[];
  fullServiceInformationCache: { [p: string]: ServiceInformation }[];
  insurancesCache: { [p: string]: any }[];
  operatorNavItems: NavItem[];
  specializationsCache: string[];
  accountingServicesCache: FullService[];
  accountingServicesStartCache: FullService[];
  accountingServicesPriorityCache: FullService[];
  reportingServicesCache: FullService[];
  reportingServicesSortCache: DataTableSortOptions | null;
  reportingUsersCache: User[];
  reportingUsersSortCache: DataTableSortOptions | null;
  isImpersonating: boolean;
  impersonatedUserId: string | null;
}

const useOperatorDataStore = defineStore('OperatorData', {
  /**
    Define defaults
  */
  state: (): OperatorDataState => ({
    servicesViewCache: {
      operatorServicesView: '',
      start: '',
      calendarView: '',
    },
    servicesCache: [],
    servicesSortCache: null,
    allInclusiveServicesSortCache: null,
    tieredServicesSortCache: null,
    serviceSubmissionsSortCache: null,
    servicesTieredCache: [],
    servicesAllInclusiveCache: [],
    serviceSubmissionsCache: [],
    serviceAreasCache: [],
    serviceAreasSortCache: null,
    usersCache: [],
    usersSortCache: null,
    fullUsersCache: [],
    fullServicesCache: [],
    fullServiceInformationCache: [],
    insurancesCache: [],
    operatorNavItems: [],
    specializationsCache: [],
    accountingServicesCache: [],
    accountingServicesStartCache: [],
    accountingServicesPriorityCache: [],
    reportingServicesCache: [],
    reportingServicesSortCache: null,
    reportingUsersCache: [],
    reportingUsersSortCache: null,
    isImpersonating: false,
    impersonatedUserId: null,
  }),
  actions: {
    startImpersonation(userId: string) {
      this.isImpersonating = true;
      this.impersonatedUserId = userId;
    },
    stopImpersonation() {
      this.isImpersonating = false;
      this.impersonatedUserId = null;
    },
    /**
     * reset partner store
     * @return {void}
     */
    resetServicesViewCache(): void {
      this.servicesViewCache = {
        operatorServicesView: '',
        start: '',
        calendarView: '',
      };
    },
    /**
     * reset partner store
     * @return {void}
     */
    resetOperatorStore(): void {
      this.servicesViewCache = {
        operatorServicesView: '',
        start: '',
        calendarView: '',
      };
      this.servicesCache = [];
      this.servicesSortCache = null;
      this.allInclusiveServicesSortCache = null;
      this.tieredServicesSortCache = null;
      this.serviceSubmissionsSortCache = null;
      this.servicesTieredCache = [];
      this.servicesAllInclusiveCache = [];
      this.serviceSubmissionsCache = [];
      this.serviceAreasCache = [];
      this.serviceAreasSortCache = null;
      this.usersCache = [];
      this.usersSortCache = null;
      this.fullUsersCache = [];
      this.fullServicesCache = [];
      this.fullServiceInformationCache = [];
      this.insurancesCache = [];
      this.operatorNavItems = [];
      this.specializationsCache = [];
      this.accountingServicesCache = [];
      this.accountingServicesStartCache = [];
      this.accountingServicesPriorityCache = [];
      this.reportingServicesCache = [];
      this.reportingServicesSortCache = null;
      this.reportingUsersCache = [];
      this.reportingUsersSortCache = null;
      this.isImpersonating = false;
      this.impersonatedUserId = null;
    },
  },
  persist: {
    key: 'arztpool24.de-operators',
    debug: Debug.isEnabled(),
  },
});

export default useOperatorDataStore;
