<template>
  <div
    @click="open = !open"
    :class="{
      open: open,
      sm: smAndDown,
    }"
    id="modalContact"
    class="d-md-flex justify-center align-center"
  >
    <div v-show="!open">
      <v-icon icon="far fa-comment" class="closeIcon"></v-icon>
    </div>
    <div v-show="open">
      <p class="mb-2 h2" style="white-space: pre-line">{{ $t('CONTACT_MODAL.HEADING') }}</p>
      <p class="mb-0 d-flex align-center">
        <v-icon icon="far fa-comment" class="mr-3" left></v-icon>
        <a :href="'tel:' + $t('CONTACT_MODAL.PHONE')">
          {{ $t('CONTACT_MODAL.PHONE') }}
        </a>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { useDisplay } from 'vuetify';

  export default defineComponent({
    name: 'ModalContact',
    components: {},
    setup() {
      const { smAndDown } = useDisplay();
      return {
        open: ref(false),
        smAndDown,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';
  #modalContact {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 90px;
    height: 80px;
    position: fixed;
    bottom: 112px;
    right: 0;
    background-color: $color-white;
    -webkit-box-shadow: 0 0 10px 0 $color-blue-grey-100;
    box-shadow: 0 0 10px 0 $color-blue-grey-100;
    z-index: 1;
    overflow: hidden;
    transition: height 0.1s, width 0.1s;
    user-select: none;

    &.sm {
      width: 65px;
      height: 50px;
      .closeIcon {
        padding: 10px;
      }
    }

    &.sm.open {
      padding: 20px 30px;
      width: 235px;
    }

    &.open {
      width: 264px;
      height: 131px;
    }

    &:hover {
      cursor: pointer;
    }

    div > .v-icon {
      font-size: 50px;
      color: $color-sky;
    }

    p {
      .v-icon {
        font-size: 20px;
        color: currentColor;
      }

      &:last-child {
        color: $color-sky;
      }
    }
  }
</style>
