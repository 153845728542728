export default {
  REQUIRED: 'Bitte ausfüllen!',
  EMAIL: {
    LENGTH: 'Max. 64 Zeichen erlaubt',
    INVALID: 'E-Mail ungültig',
  },
  PASSWORD: {
    INVALID: 'Mind. 8 Zeichen, mind. ein Großbuchstabe, mind. eine Zahl, mind. ein Sonderzeichen',
    MISMATCH: 'Die Passwörter müssen übereinstimmen',
  },
  PHONE: {
    INVALID: 'Telefonnummer ungültig',
  },
  NUMBER: {
    INVALID: 'Nummer ungültig',
  },
  FILE_INPUT: {
    INVALID_TYPE: 'Ungültiges Dateiformat',
  },
};
